import React from "react";

function Loader(props) {
  return (
    <>
      <p class="loading-text">
        {" "}
        <div class="loading"></div>
        <span>{props.loadingText}</span>
      </p>
    </>
  );
}

export default Loader;
