import React, { useState, useEffect, useContext } from "react";
import { useWallet, UseWalletProvider } from "use-wallet";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import TradeData from "../components/TradeData";
import Datarow from "../components/Datarow";
import Burnrow from "../components/Burnrow";
import StatRow from "../components/StatRow";
import Dropdownfrom from "../components/Dropdownfrom";
import Dropdownto from "../components/Dropdownto";
import Footer from "../components/Footer";
import { store } from "react-notifications-component";
import ls from "local-storage";
import abiVal from "../data/abi.json";
import { CommonDataContext } from "./CommonDataContext";
import bnbicon from "../app/img/bnbicon.png";
import tagicon from "../app/img/favicon.png";
import busdicon from "../app/img/busdicon.png";
import tmoonicon from "../app/img/tmoonicon.png";
import egoldicon from "../app/img/egoldicon.png";
import stagicon from "../app/img/stagicon.png";
import popup from "../images/popup/lbank-listing.jpeg";
import LoginCheck from "../components/LoginCheck";

const Web3 = require("web3");
const web3 = new Web3("https://bsc-dataseed.binance.org/");
const CONTRACT_ADDRESS = abiVal.pswapcontract;
const CONTRACT_ABI = abiVal.pswapabi;

const TMOON_ADDRESS = abiVal.tmooncontract;
const STAG_ADDRESS = abiVal.stagcontract;

const EGOLD_ADDRESS = abiVal.egoldcontract;

const TAG_ADDRESS = abiVal.tagcontract;
const TAG_ABI = abiVal.tagabi;

const BUSD_ADDRESS = abiVal.firsttokencontract;
const BUSD_ABI = abiVal.firsttokenabi;

const PAIR_ABI = abiVal.pairabi;

const FACTORY_ADDRESS = abiVal.factorycontract;
const FACTORY_ABI = abiVal.factoryabi;

localStorage.setItem("flag", "false");
localStorage.setItem("ttype", "0");

function TradePan() {
  const valContext = useContext(CommonDataContext);
  const {
    runCommand,
    setrunCommand,
    state,
    setState,
    latstate,
    setLatstate,
    marketstate,
    setMarketstate,
    marketval,
    setMarketval,
    tagholders,
    setTagholders,
    htagreg,
    setHtagreg,
    htaghold,
    setHtaghold,
    stakecount,
    setStakecount,
    tradestate,
    setTradestate,
    from,
    setFrom,
    to,
    setTo,
    setUnmintedtoken,
  } = valContext;
  const wallet = useWallet();
  let history = useHistory();
  const webb3 = new Web3(wallet.ethereum);
  const contractInstance = new webb3.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );
  const tagcontractInstance = new webb3.eth.Contract(TAG_ABI, TAG_ADDRESS);
  const busdcontractInstance = new webb3.eth.Contract(BUSD_ABI, BUSD_ADDRESS);

  const contractInstancew3 = new web3.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );
  const factoryInstancew3 = new web3.eth.Contract(FACTORY_ABI, FACTORY_ADDRESS);
  //we can interact with BNB directly from the pancakeswap router contract

  const [fromval, setFromval] = useState("");
  const [toval, setToval] = useState("");
  const [tagbalance, setTagbal] = useState("");
  const [frombalance, setFrombal] = useState("");
  const [tobalance, setTobal] = useState("");
  const [fromprice, setFromprice] = useState("");
  const [toprice, setToprice] = useState("");
  const [fromliq, setFromliq] = useState("");
  const [toliq, setToliq] = useState("");
  const [rangeval, setRangeval] = useState(1);
  const [tokenapp, setTokenapp] = useState(true); //token approve button enabled
  const [tagapp, setTagapp] = useState(true); //tag approve button enabled
  const [loadinggadd, setLoadinggadd] = useState(false);
  const [loadinggaptok, setLoadinggaptok] = useState(false);
  const [loadinggaptag, setLoadinggaptag] = useState(false);
  const [tmoonprice, setTmoonprice] = useState("");
  const [stagprice, setStagprice] = useState("");

  // const [open, setOpen] = useState(true);//modal popup trigger
  // const [psession, setPsession] = useState('');

  // const onCloseModal = () => {setOpen(false);sessionStorage.setItem("popup", false); setPsession(false);} //close info-popup modal

  useEffect(() => {
    getTokbal();

    //for modal
    // if(!sessionStorage.getItem("popup"))
    // {
    //   setPsession(true)
    // }
    // else if(sessionStorage.getItem("popup")==false)
    // {
    //   setPsession(false);
    // }
  }, []);

  useEffect(() => {
    handlefromChange(fromval);
    getTokbal();
  }, [from, to]);

  useEffect(() => {
    if (runCommand == 0) {
      setFrombal("0");
      setTobal("0");
      setTagbal("0");
    } else getTokbal();
  }, [runCommand]);

  useEffect(() => {
    //initial load

    setState((state) => ({ data: state.data, error: false, loading: true }));
    setLatstate((latstate) => ({
      data: latstate.data,
      error: false,
      loading: true,
    }));
    const url = "https://tagdev.info/v1/summary/getLatestTrades/20";
    const urlper =
      "https://tagdev.info/v1/summary/getLatestTrades/20/" + ls.get("usradd");
    const url2 = "https://tagdev.info/v1/summary/get24MarketStat";
    const regurl = "https://tagcoin-api.tagdev.info/v1/summary/";
    const marketurl = "https://tagdev.info/v1/summary/";
    const latestblockurl =
      "https://ledger-trans.tagdev.info/v1/summary/getLastIndexed/last";
    const unmintedurl = "https://ledger.tagdev.info/v1/summary/getUnminted";
    console.log("fetching");

    //fetch current price from last trade
    fetch(url)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) =>
        setState({ data: newData, error: false, loading: false })
      )
      .catch(function (error) {
        console.log(error);
      });

    //fetch trade data
    fetch(ls.get("ttype") == 0 ? url : urlper)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) =>
        setLatstate({ data: newData, error: false, loading: false })
      )
      .catch(function (error) {
        console.log(error);
      });

    //fetch 24 hour market data
    fetch(url2)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) =>
        setMarketstate({ data: newData, error: false, loading: false })
      )
      .catch(function (error) {
        console.log(error);
      });

    //fetch registered count data

    fetch(regurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setHtagreg(data.hashtags);
        setHtaghold(data.wallets);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //fetch supply and liquidity data

    fetch(marketurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setMarketval({
          supply: data.supply,
          totaltag: data.pools.totalTag,
          totalusd: data.pools.totalUsd,
          lockedsupply: data.lockedSupply,
        });
        setTagholders(data.holders);
        setTmoonprice(data.pools.tmoonusd);
        setStagprice(data.pools.stagusd);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //fetch staking count data

    fetch(latestblockurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return fetch(
          "https://ledger-trans.tagdev.info/v1/summary/getSummary/" + data.id
        );
      })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setStakecount(data.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //fetch unminted tokens

    fetch(unmintedurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setUnmintedtoken(data.unminted);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //load with interval

    const interval = setInterval(() => {
      const url2 = "https://tagdev.info/v1/summary/get24MarketStat";
      console.log("fetching");

      //fetch current price from last trade
      fetch(url)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setState({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });

      fetch(ls.get("ttype") == 0 ? url : urlper)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setLatstate({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });

      fetch(url2)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setMarketstate({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });
    }, 15000);

    const interval2 = setInterval(() => {
      //fetch registered count data

      fetch(regurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setHtagreg(data.hashtags);
          setHtaghold(data.wallets);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });

      //fetch supply and liquidity data

      fetch(marketurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setMarketval({
            supply: data.supply,
            totaltag: data.pools.totalTag,
            totalusd: data.pools.totalUsd,
            lockedsupply: data.lockedSupply,
          });
          setTagholders(data.holders);
          setTmoonprice(data.pools.tmoonusd);
          setStagprice(data.pools.stagusd);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });

      //fetch staking count data

      fetch(latestblockurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          return fetch(
            "https://ledger-trans.tagdev.info/v1/summary/getSummary/" + data.id
          );
        })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setStakecount(data.count);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });

      //fetch unminted tokens

      fetch(unmintedurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setUnmintedtoken(data.unminted);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });
    }, 60000);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, []);

  //to not round values while showing balance
  function noround(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  //for delaying retreiving token balances during transaction processing
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const getTokbal = async () => {
    const w3 = new Web3("https://bsc-dataseed.binance.org/");
    const invalue = 1;
    const finval = invalue.toString();
    const contractInstance2 = new w3.eth.Contract(
      CONTRACT_ABI,
      CONTRACT_ADDRESS
    );
    const tagcontractInstanceto = new w3.eth.Contract(TAG_ABI, TAG_ADDRESS);
    const busdcontractInstanceto = new w3.eth.Contract(BUSD_ABI, BUSD_ADDRESS);
    const tmooncontractInstanceto = new w3.eth.Contract(TAG_ABI, TMOON_ADDRESS);
    const stagcontractInstanceto = new w3.eth.Contract(TAG_ABI, STAG_ADDRESS);
    const egoldcontractInstanceto = new w3.eth.Contract(TAG_ABI, EGOLD_ADDRESS);

    const tagadd = TAG_ADDRESS; //stores contract address of tag -- for testing TAG

    if (from != null && to != null) {
      //add here if there are more tokens
      if (from == "BNB") {
        var tokencaddress1 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
      } else if (from == "BUSD") {
        var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (from == "TAG") {
        var tokencaddress1 = tagadd;
      } else if (from == "TMOON") {
        var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (from == "STAG") {
        var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (from == "EGOLD") {
        var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
      }

      if (to == "BNB") {
        var tokencaddress2 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
      } else if (to == "BUSD") {
        var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (to == "TAG") {
        var tokencaddress2 = tagadd;
      } else if (to == "TMOON") {
        var tokencaddress2 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (to == "STAG") {
        var tokencaddress2 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (to == "EGOLD") {
        var tokencaddress2 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
      }

      let PAIR_ADDRESS;
      await factoryInstancew3.methods
        .getPair(tokencaddress1, tokencaddress2)
        .call(function (err, res) {
          if (err) {
            console.log("An error occured", err);
            return;
          }
          PAIR_ADDRESS = res;
        });

      const pairInstanceto = new w3.eth.Contract(PAIR_ABI, PAIR_ADDRESS);
      //to get liquidity in pool
      await pairInstanceto.methods
        .getReserves()
        .call(async function (err, res) {
          if (err) {
            console.log("An error occured", err);
            return;
          }
          await pairInstanceto.methods.token0().call(function (err, ress) {
            if (err) {
              console.log("An error occured", err);
              return;
            }
            console.log("liqpool", res);
            if (ress == tokencaddress1) {
              setFromliq(parseFloat(w3.utils.fromWei(res[1])).toFixed(12));
              setToliq(parseFloat(w3.utils.fromWei(res[0])).toFixed(12));
            } else {
              setFromliq(parseFloat(w3.utils.fromWei(res[0])).toFixed(12));
              setToliq(parseFloat(w3.utils.fromWei(res[1])).toFixed(12));
            }
          });
        });
      //for calculating 1 token = x TAG
      await contractInstance2.methods
        .getAmountsOut(w3.utils.toWei(finval), [tokencaddress2, tokencaddress1])
        .call(function (err, res) {
          if (err) {
            console.log("An error occured", err);
            return;
          }
          setFromprice(w3.utils.fromWei(res[1]));
        });

      //for calculating 1 TAG = x token
      await contractInstance2.methods
        .getAmountsOut(w3.utils.toWei(finval), [tokencaddress1, tokencaddress2])
        .call(function (err, res) {
          if (err) {
            console.log("An error occured", err);
            return;
          }
          setToprice(w3.utils.fromWei(res[1]));
        });
    }
    //for calculating token balance like BNB,BUSD,TAG...
    if (from == "BNB") {
      setFrombal("BNBval");
    } else if (from == "BUSD") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await busdcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setFrombal(res);
            }
          });
      }
    } else if (from == "TMOON") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await tmooncontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setFrombal(res);
            }
          });
      }
    } else if (from == "EGOLD") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await egoldcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setFrombal(res);
            }
          });
      }
    } else if (from == "STAG") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await stagcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setFrombal(res);
            }
          });
      }
    } else if (from == "TAG") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await tagcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setFrombal(res);
            }
          });
      }
    } else if (from == null) {
      setFrombal("0");
    }

    if (to == "BNB") {
      setTobal("BNBval");
    } else if (to == "BUSD") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await busdcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setTobal(res);
            }
          });
      }
    } else if (to == "TMOON") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await tmooncontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setTobal(res);
            }
          });
      }
    } else if (to == "EGOLD") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await egoldcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setTobal(res);
            }
          });
      }
    } else if (to == "STAG") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await stagcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setTobal(res);
            }
          });
      }
    } else if (to == "TAG") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await tagcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setTobal(res);
            }
          });
      }
    } else if (to == null) {
      setTobal("0");
    }
    //add here for more tokens

    //to get top banner tag balance
    if (ls.get("usradd")) {
      await tagcontractInstanceto.methods
        .balanceOf(ls.get("usradd"))
        .call({}, function (error, res) {
          if (error != null) {
            console.log(error);
            return;
          } else {
            setTagbal(res);
          }
        });
    }
  };

  //autocompletes To input field based on value on From field
  async function handlefromChange(val) {
    if (from != null && to != null) {
      setFromval(val);
      const tagadd = TAG_ADDRESS;
      if (from == "BNB") {
        var tokencaddress1 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
      } else if (from == "BUSD") {
        var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (from == "TAG") {
        var tokencaddress1 = tagadd;
      } else if (from == "TMOON") {
        var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (from == "STAG") {
        var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (from == "EGOLD") {
        var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
      }

      if (to == "BNB") {
        var tokencaddress2 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
      } else if (to == "BUSD") {
        var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (to == "TAG") {
        var tokencaddress2 = tagadd;
      } else if (to == "TMOON") {
        var tokencaddress2 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (to == "STAG") {
        var tokencaddress2 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (to == "EGOLD") {
        var tokencaddress2 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
      }

      if (isNaN(val) || val == "") {
        setFromval("");
        setToval("");
      } else {
        await contractInstancew3.methods
          .getAmountsOut(web3.utils.toWei(val), [
            tokencaddress1,
            tokencaddress2,
          ])
          .call(function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            }
            setToval(parseFloat(webb3.utils.fromWei(res[1])).toFixed(6));
          });
      }
    }
  }

  //autocompletes From input field based on value on To field
  async function handletoChange(val) {
    if (from != null && to != null) {
      setToval(val);
      const tagadd = TAG_ADDRESS;
      if (from == "BNB") {
        var tokencaddress1 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
      } else if (from == "BUSD") {
        var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (from == "TAG") {
        var tokencaddress1 = tagadd;
      } else if (from == "TMOON") {
        var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (from == "STAG") {
        var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (from == "EGOLD") {
        var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
      }

      if (to == "BNB") {
        var tokencaddress2 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
      } else if (to == "BUSD") {
        var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (to == "TAG") {
        var tokencaddress2 = tagadd;
      } else if (to == "TMOON") {
        var tokencaddress2 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (to == "STAG") {
        var tokencaddress2 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (to == "EGOLD") {
        var tokencaddress2 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
      }

      if (isNaN(val) || val == "") {
        setFromval("");
        setToval("");
      } else {
        await contractInstancew3.methods
          .getAmountsIn(web3.utils.toWei(val), [tokencaddress1, tokencaddress2])
          .call(function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            }
            setFromval(parseFloat(webb3.utils.fromWei(res[0])).toFixed(10));
          });
      }
    }
  }

  //handle swap-arrow click
  function handleFromto() {
    let newto = from;
    let newfrom = to;
    let x = fromval;
    let y = toval;
    setFrom(newfrom);
    setTo(newto);
    setFromval(y);
    setToval(x);
  }

  //To approve Token
  async function approveToken() {
    if (fromval != "" && toval != "") {
      setLoadinggaptok(true);
      let gaslim = await webb3.eth.estimateGas({ from: wallet.account });
      let gaspri = await webb3.eth.getGasPrice();
      let fromconaddr = CONTRACT_ADDRESS;
      let appvalue;
      if (from == "BUSD") {
        appvalue = fromval;
        var tokencontractInstance = new webb3.eth.Contract(
          BUSD_ABI,
          BUSD_ADDRESS
        );
      } else if (from == "TMOON") {
        appvalue = fromval;
        var tokencontractInstance = new webb3.eth.Contract(
          TAG_ABI,
          TMOON_ADDRESS
        );
      } else if (from == "STAG") {
        appvalue = fromval;
        var tokencontractInstance = new webb3.eth.Contract(
          TAG_ABI,
          STAG_ADDRESS
        );
      } else if (from == "EGOLD") {
        appvalue = fromval;
        var tokencontractInstance = new webb3.eth.Contract(
          TAG_ABI,
          EGOLD_ADDRESS
        );
      }

      await tokencontractInstance.methods
        .approve(fromconaddr, webb3.utils.toWei(appvalue))
        .send({
          from: wallet.account,
          gasLimit: gaslim,
          gasPrice: gaspri,
        })
        .on("receipt", async function (hash) {
          setTokenapp(false);
          setLoadinggaptok(false);

          store.addNotification({
            id: "approvee-complete",
            title: "Approved",
            message: "Transaction was approved",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, receipt) {
          console.log("error");
          setLoadinggadd(false);
          store.addNotification({
            id: "approvee-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    }
  }

  //To approve Tag
  async function approveTag() {
    if (fromval != "" && toval != "") {
      setLoadinggaptag(true);
      let gaslim = await webb3.eth.estimateGas({ from: wallet.account });
      let gaspri = await webb3.eth.getGasPrice();
      let fromconaddr = CONTRACT_ADDRESS;
      let appvalue;
      if (from == "TAG") {
        appvalue = fromval;
      } else if (to == "TAG") {
        appvalue = toval;
      }

      await tagcontractInstance.methods
        .approve(fromconaddr, webb3.utils.toWei(appvalue))
        .send({
          from: wallet.account,
          gasLimit: gaslim,
          gasPrice: gaspri,
        })
        .on("receipt", async function (hash) {
          setTagapp(false);
          setLoadinggaptag(false);

          store.addNotification({
            id: "approvee-complete",
            title: "Approved",
            message: "Transaction was approved",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, receipt) {
          console.log("error");
          setLoadinggadd(false);
          store.addNotification({
            id: "approvee-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    }
  }

  //function to perform the swap
  async function swapToken() {
    setLoadinggadd(true);
    const tagadd = TAG_ADDRESS;

    if (from == "BNB") {
      var tokencaddress1 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
    } else if (from == "BUSD") {
      var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
    } else if (from == "TAG") {
      var tokencaddress1 = tagadd;
    } else if (from == "TMOON") {
      var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
    } else if (from == "STAG") {
      var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
    } else if (from == "EGOLD") {
      var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
    }

    if (to == "BNB") {
      var tokencaddress2 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
    } else if (to == "BUSD") {
      var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
    } else if (to == "TAG") {
      var tokencaddress2 = tagadd;
    } else if (to == "TMOON") {
      var tokencaddress2 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
    } else if (to == "STAG") {
      var tokencaddress2 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
    } else if (to == "EGOLD") {
      var tokencaddress2 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
    }

    let endtime = Math.floor(Date.now() / 1000) + 600;
    let gaslim = await webb3.eth.estimateGas({ from: wallet.account });
    let gaspri = await webb3.eth.getGasPrice();

    if (from == "BNB") {
      var mintag = parseFloat(((100 - rangeval) / 100) * toval)
        .toFixed(16)
        .toString();
      await contractInstance.methods
        .swapExactETHForTokens(
          webb3.utils.toWei(mintag, "ether"),
          [tokencaddress1, tokencaddress2],
          wallet.account,
          endtime
        )
        .send({
          from: wallet.account,
          gasLimit: 200000,
          gasPrice: webb3.utils.toWei("10", "gwei"),
          value: webb3.utils.toWei(fromval),
        })
        .on("receipt", async function (hash) {
          console.log("Success");
          localStorage.setItem("flag", "true");
          await getTokbal();
          setLoadinggadd(false);

          store.addNotification({
            id: "purchase-complete",
            title: "Swap completed",
            message: "Swap is complete",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, hash) {
          console.log(error);
          setLoadinggadd(false);
          store.addNotification({
            id: "swap-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    } else if (to == "BNB") {
      var minbnb = parseFloat(((100 - rangeval) / 100) * toval)
        .toFixed(16)
        .toString();

      await contractInstance.methods
        .swapExactTokensForETH(
          webb3.utils.toWei(fromval),
          webb3.utils.toWei(minbnb, "ether"),
          [tokencaddress1, tokencaddress2],
          wallet.account,
          endtime
        )
        .send({
          from: wallet.account,
          gasLimit: 200000,
          gasPrice: webb3.utils.toWei("10", "gwei"),
        })
        .on("receipt", async function (hash) {
          localStorage.setItem("flag", "true");
          await getTokbal();
          setLoadinggadd(false);
          setTokenapp(true);
          setTagapp(true);

          store.addNotification({
            id: "purchase-complete",
            title: "Swap completed",
            message: "Swap is complete",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, hash) {
          console.log("error");
          setLoadinggadd(false);
          store.addNotification({
            id: "swap-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    } else {
      let fromconaddr = CONTRACT_ADDRESS;

      //add here and change contractinstance for new tokens

      var minouttok = parseFloat(((100 - rangeval) / 100) * toval)
        .toFixed(16)
        .toString();

      if (from == "TAG") {
        await contractInstance.methods
          .swapExactTokensForTokens(
            webb3.utils.toWei(fromval),
            webb3.utils.toWei(minouttok, "ether"),
            [tokencaddress1, tokencaddress2],
            wallet.account,
            endtime
          )
          .send({
            from: wallet.account,
            gasLimit: 200000,
            gasPrice: webb3.utils.toWei("10", "gwei"),
          })
          .on("receipt", async function (hash) {
            console.log(hash);
            localStorage.setItem("flag", "true");
            await getTokbal();
            setLoadinggadd(false);
            setTokenapp(true);
            setTagapp(true);

            store.addNotification({
              id: "purchase-complete",
              title: "Swap completed",
              message: "Swap is complete",
              type: "success",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              },
            });
          })
          .on("error", function (error, hash) {
            console.log("error");
            setLoadinggadd(false);
            store.addNotification({
              id: "swap-error",
              title: "Error",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 8000,
              },
            });
          });
      } else if (to == "TAG") {
        await contractInstance.methods
          .swapExactTokensForTokens(
            webb3.utils.toWei(fromval, "ether"),
            webb3.utils.toWei(minouttok, "ether"),
            [tokencaddress1, tokencaddress2],
            wallet.account,
            endtime
          )
          .send({
            from: wallet.account,
            gasLimit: 200000,
            gasPrice: webb3.utils.toWei("10", "gwei"),
          })
          .on("receipt", async function (hash) {
            console.log(hash);
            localStorage.setItem("flag", "true");
            await getTokbal();
            setLoadinggadd(false);
            setTokenapp(true);
            setTagapp(true);

            store.addNotification({
              id: "purchase-complete",
              title: "Swap completed",
              message: "Swap is complete",
              type: "success",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              },
            });
          })
          .on("error", function (error, hash) {
            console.log("error");
            setLoadinggadd(false);
            store.addNotification({
              id: "swap-error",
              title: "Error",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 8000,
              },
            });
          });
      } else {
        console.log("tata---", fromval, minouttok);
        await contractInstance.methods
          .swapExactTokensForTokens(
            webb3.utils.toWei(fromval, "ether"),
            webb3.utils.toWei(minouttok, "ether"),
            [tokencaddress1, tokencaddress2],
            wallet.account,
            endtime
          )
          .send({
            from: wallet.account,
            gasLimit: 200000,
            gasPrice: webb3.utils.toWei("10", "gwei"),
          })
          .on("receipt", async function (hash) {
            console.log(hash);
            localStorage.setItem("flag", "true");
            await getTokbal();
            setLoadinggadd(false);
            setTokenapp(true);
            setTagapp(true);

            store.addNotification({
              id: "purchase-complete",
              title: "Swap completed",
              message: "Swap is complete",
              type: "success",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              },
            });
          })
          .on("error", function (error, hash) {
            console.log("error");
            setLoadinggadd(false);
            store.addNotification({
              id: "swap-error",
              title: "Error",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 8000,
              },
            });
          });
      }
    }
  }
  function handleMaxfrom(wall, from) {
    if (from == "BNBval") {
      var x = noround(parseFloat(web3.utils.fromWei(wall), "ether"), 8);
      setFromval(x);
      handlefromChange(x);
    } else {
      var x = noround(parseFloat(web3.utils.fromWei(from), "ether"), 6);
      setFromval(x);
      handlefromChange(x);
    }
  }

  function handleMaxto(wall, to) {
    if (to == "BNBval") {
      var x = noround(parseFloat(web3.utils.fromWei(wall), "ether"), 8);
      setToval(x);
      handletoChange(x);
    } else {
      var x = noround(parseFloat(web3.utils.fromWei(to), "ether"), 6);
      setToval(x);
      handletoChange(x);
    }
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 4);
      return x; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      <LoginCheck />
      {/* <div className="flxrow">
<div className="alert tcalert alert-danger" role="alert">
  This is a primary alert—check it out!
</div></div> */}

      <div className="flxrow">
        {/* ----SWAP---- */}
        <div className="flxcolumn">
          <div className="app-content trcontent tradeheight">
            <div className="tagtrade-content">
              <h3 className="heading">Tagcoin Exchange</h3>
              <h5 className="headingin">
                Tagcoin Price :{" "}
                {state.loading
                  ? "0"
                  : parseFloat(state.data[0].TAG_USD).toFixed(4) + " USD "}
                {marketstate.loading || state.loading ? (
                  "0%"
                ) : state.data[0].TAG_USD > marketstate.data[0].open_usd ? (
                  <>
                    <svg
                      height="24px"
                      viewBox="0 0 20 28"
                      width="24px"
                      fill="#30e630"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M7 14l5-5 5 5z" />
                    </svg>
                    <span className="greenlight">
                      {parseFloat(
                        ((state.data[0].TAG_USD -
                          marketstate.data[0].open_usd) /
                          marketstate.data[0].open_usd) *
                          100
                      ).toFixed(2) + "%"}
                    </span>
                  </>
                ) : (
                  <>
                    <svg
                      height="24px"
                      viewBox="0 0 20 28"
                      width="24px"
                      fill="#ff0000"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M7 10l5 5 5-5z" />
                    </svg>
                    <span className="redlight">
                      {parseFloat(
                        ((marketstate.data[0].open_usd -
                          state.data[0].TAG_USD) /
                          marketstate.data[0].open_usd) *
                          100
                      ).toFixed(2) + "%"}
                    </span>
                  </>
                )}
              </h5>
              <div>
                <center>
                  <img
                    src="https://tagprotocol.s3.us-east-1.amazonaws.com/POWERED%20BY%20PANCAKESWAP.png"
                    alt=""
                    style={{ margin: "auto" }}
                  />
                </center>
              </div>

              <div className="tagtrade">
                {/* <ul className="nav nav-tabs">
			<li style={{textAlign:"center"}}><a href="" onClick={() => localStorage.setItem('Tokname', 'BNB')} className={tokname==="BNB"? "active show tokbutton" : "tokbutton"} >TAG/BNB</a></li>
			<li><a href="" onClick={() => localStorage.setItem('Tokname', 'BUSD')} className={tokname==="BUSD"? "active show tokbutton" : "tokbutton"} >TAG/BUSD</a></li>
		 </ul> */}
              </div>
              <ul className="nav nav-tabs">
                <li className="">
                  <a href="" className="active show">
                    SWAP
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      history.push("/liquidity");
                    }}
                  >
                    LIQUIDITY
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      history.push("/lpfarm");
                    }}
                  >
                    LP FARM
                  </a>
                </li>
              </ul>
            </div>
            <div className="body" style={{ marginTop: "20px" }}>
              <div className="containerr preload">
                <ul className="accinfo-list pb-2" style={{ padding: "0px" }}>
                  <li>
                    <label>{from}</label>
                    <span>
                      {noround(
                        parseFloat(
                          web3.utils.fromWei(
                            frombalance === "BNBval"
                              ? wallet.balance
                              : frombalance
                          ),
                          "ether"
                        ),
                        4
                      )}{" "}
                      {from}{" "}
                      {from == "TAG"
                        ? " / $" +
                          numFormatter(
                            noround(
                              parseFloat(
                                web3.utils.fromWei(tagbalance),
                                "ether"
                              ) *
                                (state.loading
                                  ? "0"
                                  : parseFloat(state.data[0].TAG_USD).toFixed(
                                      4
                                    )),
                              4
                            )
                          )
                        : ""}
                    </span>
                  </li>
                  <li>
                    <label>{to}</label>
                    <span>
                      {noround(
                        parseFloat(
                          web3.utils.fromWei(
                            tobalance === "BNBval" ? wallet.balance : tobalance
                          ),
                          "ether"
                        ),
                        4
                      )}{" "}
                      {to}{" "}
                      {to == "TAG"
                        ? " / $" +
                          numFormatter(
                            noround(
                              parseFloat(
                                web3.utils.fromWei(tagbalance),
                                "ether"
                              ) *
                                (state.loading
                                  ? "0"
                                  : parseFloat(state.data[0].TAG_USD).toFixed(
                                      4
                                    )),
                              4
                            )
                          )
                        : ""}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="form-container swap">
                <div
                  className="field-container"
                  style={{ background: "rgb(222 240 255)", padding: "12px" }}
                >
                  <label
                    className="labl"
                    htmlFor="name"
                    style={{ padding: "5px" }}
                  >
                    FROM
                  </label>
                  <label
                    className="labl"
                    htmlFor="bal"
                    style={{ padding: "2px", float: "right" }}
                  >
                    Balance :{" "}
                    {noround(
                      parseFloat(
                        web3.utils.fromWei(
                          frombalance === "BNBval"
                            ? wallet.balance
                            : frombalance
                        ),
                        "ether"
                      ),
                      4
                    )}
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      id="bnb"
                      className="inp"
                      maxLength="10"
                      type="text"
                      name="fromvalue"
                      style={{
                        background: "transparent",
                        border: "0px",
                        outline: "none",
                      }}
                      placeholder="0.0"
                      onChange={(e) => handlefromChange(e.target.value)}
                      value={fromval}
                    />
                    <div
                      style={{
                        display: "inline-flex",
                        position: "absolute",
                        right: "8px",
                        zIndex: "101",
                      }}
                    >
                      <button
                        style={{
                          background: "transparent",
                          border: "0px",
                          height: "16px",
                          marginRight: "15px",
                          marginTop: "7px",
                          fontWeight: "700",
                          color: "#1e82ce",
                          cursor: "pointer",
                          outline: "0px",
                        }}
                        onClick={(e) =>
                          handleMaxfrom(wallet.balance, frombalance)
                        }
                      >
                        MAX
                      </button>
                      <Dropdownfrom />
                    </div>
                  </div>
                </div>
                <div className="downarroww">
                  <a onClick={handleFromto}>
                    <svg
                      height="24px"
                      viewBox="0 0 24 24"
                      width="24px"
                      fill="#000000"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z"></path>
                    </svg>
                  </a>
                </div>
                <div
                  className="field-container"
                  style={{
                    background: "rgb(222 240 255)",
                    padding: "12px",
                    marginTop: "2%",
                  }}
                >
                  <label
                    className="labl"
                    htmlFor="name"
                    style={{ padding: "5px" }}
                  >
                    TO
                  </label>
                  <label
                    className="labl"
                    htmlFor="bal"
                    style={{ padding: "2px", float: "right" }}
                  >
                    Balance :{" "}
                    {noround(
                      parseFloat(
                        web3.utils.fromWei(
                          tobalance === "BNBval" ? wallet.balance : tobalance
                        ),
                        "ether"
                      ),
                      4
                    )}
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      id="tag"
                      className="inp"
                      maxLength="20"
                      type="text"
                      name="tovalue"
                      style={{
                        background: "transparent",
                        border: "0px",
                        outline: "none",
                      }}
                      placeholder="0.0"
                      onChange={(e) => handletoChange(e.target.value)}
                      value={toval}
                    />
                    <div
                      style={{
                        display: "inline-flex",
                        position: "absolute",
                        right: "8px",
                        zIndex: "100",
                      }}
                    >
                      <button
                        style={{
                          display: "inline-flex",
                          background: "transparent",
                          border: "0px",
                          height: "16px",
                          marginRight: "15px",
                          marginTop: "7px",
                          fontWeight: "700",
                          color: "#1e82ce",
                          cursor: "pointer",
                          outline: "0px",
                        }}
                        onClick={(e) => handleMaxto(wallet.balance, tobalance)}
                      >
                        MAX
                      </button>
                      <Dropdownto />
                    </div>
                  </div>
                </div>
                {to != null && from != null && (
                  <div
                    className="tagtrade allbox"
                    style={{ gridColumn: "1/3" }}
                  >
                    <div className="pricebox">
                      <div
                        className="tagbnb mb-1"
                        style={{ padding: "5px 0px", fontSize: "11px" }}
                      >
                        <img
                          src={
                            to == "BNB"
                              ? bnbicon
                              : to == "TAG"
                              ? tagicon
                              : to == "BUSD"
                              ? busdicon
                              : to == "TMOON"
                              ? tmoonicon
                              : to == "EGOLD"
                              ? egoldicon
                              : stagicon
                          }
                          alt=""
                          style={{ height: "20px", marginRight: "2px" }}
                        />
                        <span className="ml-1 mr-1">1 {to}</span>{" "}
                        <label>=</label>{" "}
                        <span className="ml-1">
                          {from == "TAG"
                            ? noround(parseFloat(fromprice), 5)
                            : noround(parseFloat(fromprice), 8)}{" "}
                          {from}
                        </span>{" "}
                        {/* replace with TAG */}
                      </div>
                      <div
                        className="tagbnb mb-1"
                        style={{ padding: "5px 0px", fontSize: "11px" }}
                      >
                        <img
                          src={
                            from == "BNB"
                              ? bnbicon
                              : from == "TAG"
                              ? tagicon
                              : from == "BUSD"
                              ? busdicon
                              : from == "TMOON"
                              ? tmoonicon
                              : from == "EGOLD"
                              ? egoldicon
                              : stagicon
                          }
                          alt=""
                          style={{ height: "20px", marginRight: "2px" }}
                        />
                        <span className="ml-1 mr-1">1 {from}</span>{" "}
                        <label>=</label>{" "}
                        <span className="ml-1">
                          {to == "TAG"
                            ? noround(parseFloat(toprice), 5)
                            : noround(parseFloat(toprice), 8)}{" "}
                          {to}
                        </span>{" "}
                        {/* replace with TAG */}
                      </div>
                    </div>
                    <div className="pricebox rightbox">
                      <div
                        className="tagbnb mb-1"
                        style={{ padding: "5px 0px", fontSize: "11px" }}
                      >
                        <img
                          src={
                            to == "BNB"
                              ? bnbicon
                              : to == "TAG"
                              ? tagicon
                              : to == "BUSD"
                              ? busdicon
                              : to == "TMOON"
                              ? tmoonicon
                              : to == "EGOLD"
                              ? egoldicon
                              : stagicon
                          }
                          alt=""
                          style={{ height: "20px", marginRight: "2px" }}
                        />
                        <span className="ml-1 mr-1">{to} IN POOL</span>{" "}
                        <label>:</label>{" "}
                        <span className="ml-1">
                          {numFormatter(parseFloat(fromliq))} {to}
                        </span>{" "}
                        {/* replace with TAG */}
                      </div>
                      <div
                        className="tagbnb mb-1"
                        style={{ padding: "5px 0px", fontSize: "11px" }}
                      >
                        <img
                          src={
                            from == "BNB"
                              ? bnbicon
                              : from == "TAG"
                              ? tagicon
                              : from == "BUSD"
                              ? busdicon
                              : from == "TMOON"
                              ? tmoonicon
                              : from == "EGOLD"
                              ? egoldicon
                              : stagicon
                          }
                          alt=""
                          style={{ height: "20px", marginRight: "2px" }}
                        />
                        <span className="ml-1 mr-1">{from} IN POOL</span>{" "}
                        <label>:</label>{" "}
                        <span className="ml-1">
                          {numFormatter(parseFloat(toliq))} {from}
                        </span>{" "}
                        {/* replace with TAG */}
                      </div>
                    </div>
                    <div className="tagbnb d-flex justify-content-center">
                      <label>Slippage Tolerance:</label>
                      <span className="ml-1" style={{ color: "#ffb16e" }}>
                        {rangeval}%
                      </span>
                    </div>
                    <div className="tagbnb d-flex justify-content-center">
                      <input
                        className="slippageslide"
                        type="range"
                        value={rangeval}
                        min="0"
                        max="15"
                        step="0.25"
                        onChange={(e) => setRangeval(e.target.value)}
                        onMouseMove={(e) => setRangeval(e.target.value)}
                      />
                    </div>
                    <div className="tagbnb d-flex justify-content-center">
                      <label>Minimum Received:</label>
                      <span className="ml-1">
                        {noround(
                          parseFloat(((100 - rangeval) / 100) * toval),
                          9
                        )}{" "}
                        {to}
                      </span>
                    </div>
                  </div>
                )}
                {(to == null || from == null) && (
                  <>
                    <div style={{ minHeight: "50px" }}></div>
                  </>
                )}

                {(from == "BNB" || to == null || from == null) && (
                  <div
                    className="field-container trload"
                    style={{
                      marginTop: "20px",
                      gridColumn: "1/3",
                      borderRadius: "5px",
                    }}
                  >
                    <button
                      className={
                        loadinggadd === true
                          ? "swapbutton"
                          : "swapbutton disnone"
                      }
                      onClick={swapToken}
                    >
                      SWAP TOKEN
                      <Loader />
                    </button>
                  </div>
                )}

                {(to == "BNB" || (to != "BNB" && from != "BNB")) &&
                  to != null &&
                  from != null && (
                    <div
                      className="form-container"
                      style={{ gridColumn: "1/3" }}
                    >
                      {from == "TAG" && (
                        <>
                          <div
                            className="field-container trloadsm"
                            style={{
                              marginTop: "20px",
                              gridColumn: "1",
                              marginRight: "3%",
                              borderRadius: "5px",
                            }}
                          >
                            <button
                              className={
                                loadinggaptag === true
                                  ? "swapbutton"
                                  : "swapbutton disnone"
                              }
                              onClick={approveTag}
                              disabled={!tagapp}
                            >
                              APPROVE TAG
                              <Loader />
                            </button>
                          </div>
                          <div
                            className="field-container trloadsm"
                            style={{
                              marginTop: "20px",
                              gridColumn: "2/3",
                              gridRow: "1",
                              marginLeft: "3%",
                              borderRadius: "5px",
                            }}
                          >
                            <button
                              className={
                                loadinggadd === true
                                  ? "swapbutton"
                                  : "swapbutton disnone"
                              }
                              disabled={tagapp}
                              onClick={swapToken}
                            >
                              SWAP TOKEN
                              <Loader />
                            </button>
                          </div>
                        </>
                      )}
                      {from == "BUSD" && (
                        <>
                          <div
                            className="field-container trloadsm"
                            style={{
                              marginTop: "20px",
                              gridColumn: "1",
                              marginRight: "3%",
                              borderRadius: "5px",
                            }}
                          >
                            <button
                              className={
                                loadinggaptok === true
                                  ? "swapbutton"
                                  : "swapbutton disnone"
                              }
                              onClick={approveToken}
                              disabled={!tokenapp}
                            >
                              APPROVE BUSD
                              <Loader />
                            </button>
                          </div>
                          <div
                            className="field-container trloadsm"
                            style={{
                              marginTop: "20px",
                              gridColumn: "2/3",
                              gridRow: "1",
                              marginLeft: "3%",
                              borderRadius: "5px",
                            }}
                          >
                            <button
                              className={
                                loadinggadd === true
                                  ? "swapbutton"
                                  : "swapbutton disnone"
                              }
                              disabled={tokenapp}
                              onClick={swapToken}
                            >
                              SWAP TOKEN
                              <Loader />
                            </button>
                          </div>
                        </>
                      )}
                      {from == "TMOON" && (
                        <>
                          <div
                            className="field-container trloadsm"
                            style={{
                              marginTop: "20px",
                              gridColumn: "1",
                              marginRight: "3%",
                              borderRadius: "5px",
                            }}
                          >
                            <button
                              className={
                                loadinggaptok === true
                                  ? "swapbutton"
                                  : "swapbutton disnone"
                              }
                              onClick={approveToken}
                              disabled={!tokenapp}
                            >
                              APPROVE TMOON
                              <Loader />
                            </button>
                          </div>
                          <div
                            className="field-container trloadsm"
                            style={{
                              marginTop: "20px",
                              gridColumn: "2/3",
                              gridRow: "1",
                              marginLeft: "3%",
                              borderRadius: "5px",
                            }}
                          >
                            <button
                              className={
                                loadinggadd === true
                                  ? "swapbutton"
                                  : "swapbutton disnone"
                              }
                              disabled={tokenapp}
                              onClick={swapToken}
                            >
                              SWAP TOKEN
                              <Loader />
                            </button>
                          </div>
                        </>
                      )}
                      {from == "EGOLD" && (
                        <>
                          <div
                            className="field-container trloadsm"
                            style={{
                              marginTop: "20px",
                              gridColumn: "1",
                              marginRight: "3%",
                              borderRadius: "5px",
                            }}
                          >
                            <button
                              className={
                                loadinggaptok === true
                                  ? "swapbutton"
                                  : "swapbutton disnone"
                              }
                              onClick={approveToken}
                              disabled={!tokenapp}
                            >
                              APPROVE EGOLD
                              <Loader />
                            </button>
                          </div>
                          <div
                            className="field-container trloadsm"
                            style={{
                              marginTop: "20px",
                              gridColumn: "2/3",
                              gridRow: "1",
                              marginLeft: "3%",
                              borderRadius: "5px",
                            }}
                          >
                            <button
                              className={
                                loadinggadd === true
                                  ? "swapbutton"
                                  : "swapbutton disnone"
                              }
                              disabled={tokenapp}
                              onClick={swapToken}
                            >
                              SWAP TOKEN
                              <Loader />
                            </button>
                          </div>
                        </>
                      )}
                      {from == "STAG" && (
                        <>
                          <div
                            className="field-container trloadsm"
                            style={{
                              marginTop: "20px",
                              gridColumn: "1",
                              marginRight: "3%",
                              borderRadius: "5px",
                            }}
                          >
                            <button
                              className={
                                loadinggaptok === true
                                  ? "swapbutton"
                                  : "swapbutton disnone"
                              }
                              onClick={approveToken}
                              disabled={!tokenapp}
                            >
                              APPROVE STAG
                              <Loader />
                            </button>
                          </div>
                          <div
                            className="field-container trloadsm"
                            style={{
                              marginTop: "20px",
                              gridColumn: "2/3",
                              gridRow: "1",
                              marginLeft: "3%",
                              borderRadius: "5px",
                            }}
                          >
                            <button
                              className={
                                loadinggadd === true
                                  ? "swapbutton"
                                  : "swapbutton disnone"
                              }
                              disabled={tokenapp}
                              onClick={swapToken}
                            >
                              SWAP TOKEN
                              <Loader />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
              </div>
              <div
                className="row"
                style={{ margin: "0 auto", textAlign: "center" }}
              >
                <h5
                  className="col-md-6"
                  style={{
                    position: "relative",
                    bottom: "-18px",
                    float: "none",
                    display: "inline",
                    fontSize: "12px",
                  }}
                >
                  TMOON Price :{" "}
                  {state.loading
                    ? "0"
                    : parseFloat(tmoonprice).toFixed(6) + " USD "}
                </h5>
                <h5
                  className="col-md-6"
                  style={{
                    position: "relative",
                    bottom: "-18px",
                    float: "none",
                    display: "inline-block",
                    fontSize: "12px",
                  }}
                >
                  STAG Price :{" "}
                  {state.loading
                    ? "0"
                    : parseFloat(stagprice).toFixed(6) + " USD "}
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="flxcolumn">
          <div className="app-content trcontent tradeheight">
            <TradeData />
          </div>
        </div>
      </div>

      <Datarow />
      <Burnrow />
      <StatRow />

      <br />

      <Footer />

      {/* <div id="open-modal2" className="modal-window2" style={{display: open&&psession ? "block" : "none"}}>
        <div>
          <a href="#" title="Close" className="modal-close" onClick={onCloseModal}>Close</a>
          <img src={popup} style={{width:"100%"}}/>
      <p className="pstyle" style={{textAlign:"center",marginTop:"10px",fontSize:"0.8rem",padding:"10px"}}>You can open your account with <a href="https://twitter.com/LBank_Exchange" target="_blank">@LBank_Exchange</a> and slowly start to deposit your tagcoins. <br/>
      <br className="showm"/>
        Get ready for trading $TAG / $USDT<br/>
        💡Listing details <a href="https://support.lbank.site/hc/en-gb/articles/6646546775833"  target="_blank">bit.ly/3MliCX8</a></p>

        </div>
      </div> */}
    </>
  );
}
export default TradePan;
