import React, { useContext, useState } from "react";
import { CommonDataContext } from "./CommonDataContext";
import Loader from "../components/Loader";
import ls from "local-storage";

function TradeData() {
  const valContext = useContext(CommonDataContext);
  const { latstate, setLatstate, marketstate, tradestate, setTradestate } =
    valContext;

  const [trtype, setTrtype] = useState(0);

  function handleTtype(x) {
    if (x == 0) {
      setTrtype(0);
      setLatstate({ loading: true });
      const url = "https://tagdev.info/v1/summary/getLatestTrades/20";
      console.log("fetching");

      //fetch trade data
      fetch(url)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setLatstate({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });
      ls.set("ttype", 0);
    } else {
      setTrtype(1);
      setLatstate({ loading: true });
      const url =
        "https://tagdev.info/v1/summary/getLatestTrades/20/" + ls.get("usradd");
      console.log("fetching");

      //fetch trade data
      fetch(url)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setLatstate({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });
      ls.set("ttype", 1);
    }
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <div className="tagtrade-content">
      <h5
        className="headingin"
        style={{ float: "none", textAlign: "center", marginBottom: "20px" }}
      >
        <span style={{ fontWeight: "bold" }}>Vol 24 Hours :</span>{" "}
        {marketstate.loading
          ? "0"
          : numFormatter(
              parseFloat(marketstate.data[0].tagvol_tag).toFixed(4)
            ) +
            " TAG / " +
            numFormatter(
              parseFloat(marketstate.data[0].tagvol_bnb).toFixed(4)
            ) +
            " BNB"}
      </h5>
      <ul className="nav nav-tabs">
        <li className="nav-item" style={{ cursor: "pointer" }}>
          <a
            className={trtype == 0 ? "nav-link active" : "nav-link"}
            aria-current="page"
            onClick={() => handleTtype(0)}
          >
            Latest Trades
          </a>
        </li>
        <li
          className="nav-item"
          style={{ cursor: "pointer", margin: "0 0 0 5px" }}
        >
          <a
            className={trtype == 1 ? "nav-link active" : "nav-link"}
            onClick={() => handleTtype(1)}
          >
            Your Trades
          </a>
        </li>
      </ul>
      <div className="body tdbdy" style={{ overflow: "auto" }}>
        <table id="squadTable" class="table">
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>
                <div class="checkbox-ios checkbox-ios--blue">
                  <input
                    class="checkbox-ios__toggle"
                    id="checkboxQuestionBlue"
                    name="checkboxQuestionBlue"
                    type="checkbox"
                    defaultChecked={tradestate}
                    onChange={() => setTradestate(!tradestate)}
                  />
                  <label class="checkbox-ios__label" for="checkboxQuestionBlue">
                    <span class="checkbox-ios__value left">USD</span>
                    <span class="checkbox-ios__value right">BNB</span>
                  </label>
                </div>
              </th>
              <th>Qty</th>
              <th>Price {tradestate ? "BNB" : "USD"}</th>
              <th>Total {tradestate ? "BNB" : "USD"}</th>
              <th>Tx Hash</th>
            </tr>
          </thead>
          <tbody className="trbody">
            {latstate.loading ? (
              <Loader />
            ) : (
              Object.keys(latstate.data).map((k, i) => {
                let data = latstate.data;
                return (
                  <tr
                    key={i}
                    className={
                      data[i].type === "buy"
                        ? "buytr"
                        : data[i].toAsset == "BNB" || data[i].toAsset == "BUSD"
                        ? "selltr"
                        : "buytr"
                    }
                    style={{ textAlign: "center" }}
                  >
                    <td style={{ textTransform: "capitalize" }}>
                      {data[i].type == "buy"
                        ? data[i].toAsset + "-TAG"
                        : "TAG-" + data[i].toAsset}
                    </td>
                    <td>
                      {data[i].type === "buy"
                        ? parseFloat(data[i].tagVol).toFixed(4) + " TAG"
                        : data[i].toAsset == "BNB" || data[i].toAsset == "BUSD"
                        ? parseFloat(data[i].tagVol).toFixed(4) + " TAG"
                        : parseFloat(data[i].srcOut).toFixed(4) +
                          " " +
                          data[i].toAsset}
                    </td>
                    <td>
                      {tradestate
                        ? data[i].type == "sell" &&
                          data[i].toAsset !== "BNB" &&
                          data[i].toAsset !== "BUSD"
                          ? parseFloat(
                              data[i].tagBNBVol / data[i].srcOut
                            ).toFixed(8) + " BNB"
                          : parseFloat(data[i].TAG_BNB).toFixed(8) + " BNB"
                        : data[i].type == "sell" &&
                          data[i].toAsset !== "BNB" &&
                          data[i].toAsset !== "BUSD"
                        ? "$" +
                          parseFloat(
                            data[i].tagUSDVol / data[i].srcOut
                          ).toFixed(4)
                        : "$" + parseFloat(data[i].TAG_USD).toFixed(4)}
                    </td>
                    <td>
                      {tradestate
                        ? parseFloat(data[i].tagBNBVol).toFixed(4) + " BNB"
                        : "$" + parseFloat(data[i].tagUSDVol).toFixed(4)}
                    </td>
                    <td>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={"https://bscscan.com/tx/" + data[i].txn}
                      >
                        {data[i].txn.slice(0, 8)}
                      </a>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TradeData;
