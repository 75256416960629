import React, { useContext, useState, useEffect } from "react";
import { CommonDataContext } from "./CommonDataContext";
import Loader from "../components/Loader";

function Datarow() {
  const valContext = useContext(CommonDataContext);
  const {
    state,
    marketstate,
    marketval,
    tagholders,
    unmintedtoken,
    burnstate,
  } = valContext;
  const [timeLeft, setTimeLeft] = useState("");
  const [halvingYear, setHalvingYear] = useState("");

  useEffect(() => {
    calculateTimeLeft();
  }, []);

  const calculateTimeLeft = () => {
    let currentDate = new Date();
    if (currentDate.getMonth() < 8) {
      if (currentDate.getMonth() == 7 && currentDate.getDate() > 20) {
        var halvingDate = new Date(currentDate.getFullYear() + 1, 7, 20);
        setHalvingYear(currentDate.getFullYear() + 1);
      } else {
        var halvingDate = new Date(currentDate.getFullYear(), 7, 20);
        setHalvingYear(currentDate.getFullYear());
      }
    } else {
      var halvingDate = new Date(currentDate.getFullYear() + 1, 7, 20);
      setHalvingYear(currentDate.getFullYear() + 1);
    }
    let difference = halvingDate - +new Date();
    let timeLeftt = {};
    timeLeftt = Math.floor(difference / (1000 * 60 * 60 * 24)) + 1;
    setTimeLeft(timeLeftt);
  };

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <div className="flxrow">
      {/* ----MARKET DATA---- */}
      <div className="flxcolumn">
        <div className="app-content trcontent">
          <div className="tagtrade-content">
            <div style={{ textAlign: "center" }}>
              <h3 style={{ height: "41px", marginBottom: "0px" }}>
                Tagcoin Supply
              </h3>
            </div>
            <div className="body statbody">
              <div className="containerr preload">
                {marketstate.loading ? (
                  <Loader />
                ) : (
                  <ul className="accinfo-list pb-2">
                    <li>
                      <label>Mined :</label>
                      <span>
                        {burnstate.loading
                          ? "...."
                          : (
                              parseInt(marketval.supply) +
                              parseInt(burnstate.main[0].burned) +
                              parseInt(unmintedtoken)
                            ).toLocaleString() + " TAGS "}
                      </span>
                    </li>
                    <li>
                      <label>Minted :</label>
                      <span>
                        {burnstate.loading
                          ? "...."
                          : (
                              parseInt(marketval.supply) +
                              parseInt(burnstate.main[0].burned)
                            ).toLocaleString() + " TAGS "}
                      </span>
                    </li>
                    <li>
                      <label>UNMINTED :</label>
                      <span>
                        {parseInt(unmintedtoken).toLocaleString()} TAGS
                      </span>
                    </li>
                    <li>
                      <label>Burned :</label>
                      <span>
                        {burnstate.loading
                          ? "...."
                          : parseInt(
                              burnstate.main[0].burned
                            ).toLocaleString() + " TAGS "}
                      </span>
                    </li>
                    <li>
                      <label>Locked :</label>
                      <span>
                        {burnstate.loading
                          ? "...."
                          : parseInt(marketval.lockedsupply).toLocaleString() +
                            " TAGS "}
                      </span>
                    </li>
                    {/* <li><label>Active Circulation :</label><span>{burnstate.loading ? "...." : (parseInt(marketval.supply)-parseFloat(burnstate.main[0].burned).toFixed(2)).toLocaleString()+" TAGS "}</span></li> 
              <li><label>Inactive Circulation :</label><span>{(parseInt(unmintedtoken)).toLocaleString()} TAGS</span></li>  */}
                    <li>
                      <label>CIRCULATING SUPPLY :</label>
                      <span>
                        {parseInt(marketval.supply).toLocaleString()} TAGS
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ----HASHING DATA---- */}

      <div className="flxcolumn">
        <div className="app-content trcontent">
          <div className="tagtrade-content">
            <h3 style={{ height: "41px", margin: "0px" }}>
              Tagcoin Production Rate
            </h3>

            <div className="body statbody">
              <div className="containerr preload">
                {marketstate.loading ? (
                  <Loader />
                ) : (
                  <ul className="accinfo-list pb-2">
                    <li>
                      <label>Daily Mining Output :</label>
                      <span>60,000 TAGS</span>
                    </li>
                    <li>
                      <label>Weekly Mining Output :</label>
                      <span>420,000 TAGS</span>
                    </li>
                    <li>
                      <label>Monthly Mining Output :</label>
                      <span>1,800,000 TAGS</span>
                    </li>
                    <li>
                      <label>Yearly Mining Output :</label>
                      <span>21,600,000 TAGS</span>
                    </li>
                    <li>
                      <label>Next Halving in :</label>
                      <span>
                        {timeLeft} DAYS (20 Aug {halvingYear})
                      </span>
                    </li>
                    <li>
                      <label>Next Daily Output :</label>
                      <span>30,000 TAGS</span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Datarow;
