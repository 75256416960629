import React, { useContext,useState } from "react";
import { CommonDataContext } from './CommonDataContext';
import Loader from '../components/Loader';
import ls from "local-storage";



function LiquidityData(){
    const valContext = useContext(CommonDataContext);
    const { state, liqstate,setLiqstate, marketval, tradestate, setTradestate } = valContext;
  
    const [trtype,setTrtype] = useState(0);

    function handleLtype(x){
        if(x==0){
            setTrtype(0);
            setLiqstate({loading: true});
          const url = 'https://tagdev.info/v1/summary/getLiq/20';
          console.log("fetching");
            
          //fetch trade data
          fetch(url)
          .then(data => data.json())
          .then(obj =>
            Object.keys(obj).map(key => {
              let newData = obj[key]
              newData.key = key
              return newData
            })
        )
        .then(newData => setLiqstate({ data: newData, error: false, loading: false }))
        .catch(function(error) {
            console.log(error)
        })
        ls.set('ltype',0);
      }
      else{
        setTrtype(1);
        setLiqstate({loading: true});
        const url = 'https://tagdev.info/v1/summary/getLiq/20/'+ls.get('usradd');
        console.log("fetching");
          
        //fetch trade data
        fetch(url)
        .then(data => data.json())
        .then(obj =>
          Object.keys(obj).map(key => {
            let newData = obj[key]
            newData.key = key
            return newData
          })
      )
      .then(newData => setLiqstate({ data: newData, error: false, loading: false }))
      .catch(function(error) {
          console.log(error)
      })
       ls.set('ltype',1);
      }
    }

    function numFormatter(num) {
        if(num > 999 && num < 1000000){
            return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
        }else if(num > 1000000){
            return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
        }else if(num < 1000){
            return num; // if value < 1000, nothing to do
        }
    }
  
    
    return(

        <div className="tagtrade-content">
        <h5 className="headingin" style={{float:"none",textAlign:"center",marginBottom:"20px"}}><span style={{fontWeight:"bold"}}>Liquidity :</span> {state.loading ? "0" : numFormatter((marketval.totaltag*state.data[0].TAG_USD)+marketval.totalusd)} USD</h5>
        <ul className="nav nav-tabs">
                <li className="nav-item" style={{cursor:"pointer"}}>
                    <a className={trtype==0 ? "nav-link active" : "nav-link"} aria-current="page" onClick={()=>handleLtype(0)}>Latest Add/Remove</a>
                </li>
                <li className="nav-item" style={{cursor:"pointer",margin: "0 0 0 5px"}}>
                    <a className={trtype==1 ? "nav-link active" : "nav-link"} onClick={()=>handleLtype(1)}>Your Transactions</a>
                </li>
            </ul>
            <div className="body tdbdy" style={{overflow:"auto"}}>
            <table id="squadTable" class="table">
                <thead>
                    <tr style={{textAlign:"center"}}>
                        <th><div class='checkbox-ios checkbox-ios--blue'>
                        <input class='checkbox-ios__toggle' id='checkboxQuestionBlue' name='checkboxQuestionBlue' type='checkbox' defaultChecked={tradestate} onChange={() => setTradestate(!tradestate)}/>
                        <label class='checkbox-ios__label' for='checkboxQuestionBlue'>
                            <span class='checkbox-ios__value left'>USD</span>
                            <span class='checkbox-ios__value right'>BNB</span>
                        </label>
                    </div></th>
                        <th>TAG Qty</th>
                        <th>{tradestate ? 'BNB' : 'USD'} value</th>
                        <th>Pool</th>
                        <th>LPT</th>
                        <th>Tx Hash</th>
                    </tr>
                </thead>
                <tbody className="trbody">
                {liqstate.loading ? <Loader/> : Object.keys(liqstate.data).map((k, i) => {
                    let data = liqstate.data;
                    return (
                    <tr key={i} className={data[i].type==-1? "selltr" : "buytr"} style={{textAlign:"center"}}>
                        <td style={{textTransform:"capitalize"}}>{data[i].type==-1? "REMOVE":"ADD"}</td>
                        <td>{parseFloat(data[i].TAG).toFixed(2)} TAG</td>
                        <td>{tradestate ? parseFloat(data[i].totalBNB).toFixed(6)+" BNB":"$"+parseFloat(data[i].totalUSD).toFixed(3)}</td>
                        <td>TAG/{data[i].toAsset}</td>
                        <td>{parseFloat(data[i].LPT).toFixed(3)}</td>
                        <td><a target="_blank" rel="noopener noreferrer" href={"https://bscscan.com/tx/"+data[i]._id}>{(data[i]._id).slice(0,8)}</a></td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
            </div>

            </div>
    );
}

export default LiquidityData;