import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useWallet, UseWalletProvider } from "use-wallet";
import ls from "local-storage";

const Web3 = require("web3");

function LoginCheck() {
  let history = useHistory();
  const wallet = useWallet();

  async function loginRefresh() {
    if (!ls.get("usradd")) {
      console.log("Not logged in");
      if (ls.get("usrconnt") && ls.get("usradd")) {
        console.log(ls.get("usradd"));
        await wallet
          .connect(ls.get("usrconnt"))
          .then(function (e) {})
          .catch(function (e) {});
        console.log(ls.get("usrconnt"));
        console.log(wallet.status);
      } else {
        console.log(ls.get("usradd"));
        console.log(ls.get("usrconnt"));
        console.log("Not connected LoginCheck.js");
      }
    } else {
      await wallet
        .connect(ls.get("usrconnt"))
        .then(function (e) {})
        .catch(function (e) {});
    }
  }

  useEffect(() => {
    loginRefresh();
  }, []);

  return (
    <>
      <span></span>
    </>
  );
}
export default LoginCheck;
