import React, { useContext,useState,useEffect } from "react";
import { CommonDataContext } from './CommonDataContext';
import Loader from '../components/Loader';


function Burnrow(){
    const valContext = useContext(CommonDataContext);
    const { burnstate, setBurnstate } = valContext;
 
    const [typestate, setTypestate] = useState(false);
    const [bsstate, setBSstate] = useState({ data: null, error: false, loading: true });

    useEffect(() => {

    const url = 'https://tagdev.info/v1/summary/getLatestBurns/10';
    const url2 = 'https://tagdev.info/v1/summary/getSummaryBuySell';

      setBurnstate(burnstate => ({ main: burnstate.main, data: burnstate.data, error: false, loading: true }));
      setBSstate(bsstate => ({ data: burnstate.data, error: false, loading: true }));

      //fetch trade data
      fetch(url)
      .then(data => data.json())
      .then(obj =>
        Object.keys(obj).map(key => {
          let newData = obj[key]
          newData.key = key
          return newData
        })
      )
      .then(newData => {setBurnstate({ main: newData[0],data: newData[1], error: false, loading: false });})
      .catch(function(error) {
          console.log(error)
      })

      //fetch trade data
      fetch(url2)
      .then(data => data.json())
      .then(obj =>
        Object.keys(obj).map(key => {
          let newData = obj[key]
          newData.key = key
          return newData
        })
      )
      .then(newData => {setBSstate({ data: newData, error: false, loading: false });})
      .catch(function(error) {
          console.log(error)
      })

    }, []);

    
    function numFormatter(num) {
        if(num > 999 && num < 1000000){
            return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
        }else if(num > 1000000){
            return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
        }else if(num < 1000){
            return num; // if value < 1000, nothing to do
        }
    }

      //to not round values while showing balance
  function noround(val,x){
    var float= parseFloat(val).toFixed(18);
    var num = float.toString(); 
    var n = num.slice(0, (num.indexOf("."))+(x+1)); 
    return (n);
  }

  function removeTime(date) {
    var d = date.split('T')[0];
    return d
  }
  
    return(
        
<div className="flxrow">     
  
  
  {/* ----MARKET DATA---- */}
  <div className="flxcolumn">
    <div className="app-content trcontent">
  
    <div className="tagtrade-content">
          <div style={{textAlign:"center"}}>
          <h3 style={{height:"41px",marginBottom:"0px"}}>Burning Data</h3>
          <h5 className="headingin" style={{float:"none",textAlign:"center",marginBottom:"20px"}}><span style={{fontWeight:"bold"}}>Total Burned :</span> {burnstate.loading ? "...." : numFormatter(parseFloat(burnstate.main[0].burned).toFixed(4))+" TAG "} | <br className="splitbr"/> <br className="splitbr"/><span style={{fontWeight:"bold"}}>BNB Value : </span>{burnstate.loading ? "...." : numFormatter(parseFloat(burnstate.main[0].bnb).toFixed(4))+" BNB "} | <br/> <br className="splitbropp"/><br className="splitbr"/><span style={{fontWeight:"bold"}}>USD Value : </span>{burnstate.loading ? "...." : numFormatter(parseFloat(burnstate.main[0].usd).toFixed(4))+" USD "} | <br className="splitbr"/> <br className="splitbr"/><span style={{fontWeight:"bold"}}>Count : </span>{burnstate.loading ? "...." : numFormatter(burnstate.main[0].count)+" Txs "} </h5>
          </div>
      <div className="body" style={{height:"360px",minHeight:"360px",maxHeight:"360px",overflow:"auto"}}>
      <table id="squadTable" class="table">
                <thead>
                    <tr style={{textAlign:"center"}}>
                        <th><div class='checkbox-ios checkbox-ios--blue'>
                        <input class='checkbox-ios__toggle' id='checkboxQuestionBluee' name='checkboxQuestionBluee' type='checkbox' defaultChecked={typestate} onChange={() => setTypestate(!typestate)}/>
                        <label class='checkbox-ios__label' for='checkboxQuestionBluee'>
                            <span class='checkbox-ios__value left'>USD</span>
                            <span class='checkbox-ios__value right'>BNB</span>
                        </label>
                    </div></th>
                        <th>Qty</th>
                        <th>Price {typestate ? 'BNB' : 'USD'}</th>
                        <th>Total {typestate ? 'BNB' : 'USD'}</th>
                        <th>Tx Hash</th>
                    </tr>
                </thead>
                <tbody className="trbody">
                {burnstate.loading ? <Loader/> : Object.keys(burnstate.data).map((k, i) => {
                    let data = burnstate.data;
                    return (
                  burnstate.data[i] &&
                    <tr key={i} className={data[i].type==="buy"? "buytr" : (data[i].toAsset=="BNB"||data[i].toAsset=="BUSD") ? "selltr" : "buytr"} style={{textAlign:"center"}}>
                        <td style={{textTransform:"capitalize"}}>{data[i].type=="buy" ? data[i].toAsset+'-TAG' : 'TAG-'+data[i].toAsset}</td>
                        <td>{data[i].type==="buy"? parseFloat(data[i].tagVol).toFixed(4)+" TAG" : (data[i].toAsset=="BNB"||data[i].toAsset=="BUSD") ? parseFloat(data[i].tagVol).toFixed(4)+" TAG" : parseFloat(data[i].srcOut).toFixed(4)+" "+data[i].toAsset}</td>
                        <td>{typestate ? (((data[i].type=="sell")&&((data[i].toAsset!=="BNB")&&(data[i].toAsset!=="BUSD"))) ? parseFloat(data[i].tagBNBVol/data[i].srcOut).toFixed(8)+" BNB" : parseFloat(data[i].TAG_BNB).toFixed(8)+" BNB") : (((data[i].type=="sell")&&((data[i].toAsset!=="BNB")&&(data[i].toAsset!=="BUSD"))) ? "$"+parseFloat(data[i].tagUSDVol/data[i].srcOut).toFixed(4) : "$"+parseFloat(data[i].TAG_USD).toFixed(4))}</td>
                        <td>{typestate ? parseFloat(data[i].tagBNBVol).toFixed(4)+" BNB":"$"+parseFloat(data[i].tagUSDVol).toFixed(4)}</td>
                        <td><a target="_blank" rel="noopener noreferrer" href={"https://bscscan.com/tx/"+data[i].txn}>{(data[i].txn).slice(0,8)}</a></td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
  
      </div>
      </div>
    </div>
    
  {/* ----HASHING DATA---- */}
  
    <div className="flxcolumn">
    <div className="app-content trcontent">
  
    <div className="tagtrade-content">
          <h3 style={{height:"41px",margin:"0px"}}>Daily BUY-SELL Data</h3>
  
      <div className="body" style={{height:"430px",minHeight:"430px",maxHeight:"430px",overflow:"auto"}}>
      <table id="squadTable" class="table">
                <thead>
                    <tr style={{textAlign:"center"}}>
                        <th>Date</th>
                        <th>Bought</th>
                        <th>Sold</th>
                        <th>Difference</th>
                    </tr>
                </thead>
                <tbody className="trbody">
                {bsstate.loading ? <Loader/> : Object.keys(bsstate.data).map((k, i) => {
                    let data = bsstate.data;
                    return (
                    <tr key={i} className={data[i].ds>0 ? "buytr" : "selltr"} style={{textAlign:"center"}}>
                        <td>{removeTime(data[i].date)}</td>
                        <td>{noround(data[i].b,3)} TAG</td>
                        <td>{noround(data[i].s,3)} TAG</td>
                        <td>{noround(data[i].ds,3)} TAG</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
  
      </div>
      </div>
      
    </div>
         
         
  </div>
  
    );
}

export default Burnrow;
