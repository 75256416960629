import React, { useContext } from "react";
import { CommonDataContext } from "./CommonDataContext";
import HtagChart from "../components/HtagChart";
import Loader from "../components/Loader";
import { TradeChart } from "./TradingChart";

function Datarow() {
  const valContext = useContext(CommonDataContext);
  const {
    state,
    marketstate,
    marketval,
    htagreg,
    tagholders,
    setTagholders,
    htaghold,
    setHtaghold,
    stakecount,
    marketdatastate,
    setMarketdatastate,
    unmintedtoken,
    setUnmintedtoken,
  } = valContext;

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <div className="flxrow">
      {/* ----MARKET DATA---- */}
      <div className="flxcolumn">
        <div className="app-content trcontent">
          <div className="tagtrade-content">
            <div style={{ textAlign: "center" }}>
              <h3 style={{ height: "41px", marginBottom: "0px" }}>
                Market Data
              </h3>
            </div>
            <div className="body">
              <div className="containerr preload">
                {/* <TVChartContainer /> */}
                {/* <div className="tradingview-widget-container">
                  <div id="tradingview_428b8"></div>
                  <div className="tradingview-widget-copyright">
                    <a
                      href="https://www.tradingview.com/symbols/TAGUSD_E9AC7B/?exchange=PANCAKESWAP"
                      rel="noopener"
                      target="_blank"
                    >
                      <span className="blue-text">TAGUSD_E9AC7B Chart</span>
                    </a>{" "}
                    by TradingView
                  </div>
                </div> */}
                <TradeChart />
                {marketstate.loading ? (
                  <Loader />
                ) : (
                  <ul
                    className="accinfo-list pb-2"
                    style={{ marginTop: "20px" }}
                  >
                    <li>
                      <label>PRICE :</label>
                      <span>
                        {state.loading
                          ? "0"
                          : parseFloat(state.data[0].TAG_USD).toFixed(4)}{" "}
                        USD/TAG
                      </span>
                    </li>
                    <li>
                      <label>MARKET CAP :</label>
                      <span>
                        {state.loading
                          ? "0"
                          : numFormatter(
                              marketval.supply * state.data[0].TAG_USD
                            )}{" "}
                        USD
                      </span>
                    </li>
                    <li>
                      <label>TAG HOLDERS :</label>
                      <span>{parseInt(tagholders).toLocaleString()}</span>
                    </li>
                    <li>
                      <label>HIGH (24 HOURS) :</label>
                      <span>
                        ${parseFloat(marketstate.data[0].high_usd).toFixed(4)}
                      </span>
                    </li>
                    <li>
                      <label>LOW (24 HOURS) :</label>
                      <span>
                        ${parseFloat(marketstate.data[0].low_usd).toFixed(4)}
                      </span>
                    </li>
                    <li>
                      <label>VOLUME (24 HOURS) :</label>
                      <span>
                        {numFormatter(
                          parseFloat(marketstate.data[0].tagvol_tag).toFixed(4)
                        )}{" "}
                        TAGS
                      </span>
                    </li>
                    <li>
                      <label>LIQUIDITY :</label>
                      <span>
                        {state.loading
                          ? "0"
                          : numFormatter(
                              marketval.totaltag * state.data[0].TAG_USD +
                                marketval.totalusd
                            )}{" "}
                        USD
                      </span>
                    </li>
                    <li>
                      <label>CIRCULATING SUPPLY :</label>
                      <span>
                        {parseInt(marketval.supply).toLocaleString()} TAGS
                      </span>
                    </li>
                    {/* <li><label>UNMINTED :</label><span>{(parseInt(unmintedtoken)).toLocaleString()} TAGS</span></li> */}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ----HASHING DATA---- */}

      <div className="flxcolumn">
        <div className="app-content trcontent">
          <div className="tagtrade-content">
            <h3 style={{ height: "41px", margin: "0px" }}>
              Hashtag Mining Data
            </h3>

            <div className="body">
              <div className="containerr preload">
                <HtagChart />
                {marketstate.loading ? (
                  <Loader />
                ) : (
                  <ul
                    className="accinfo-list pb-2"
                    style={{ marginTop: "20px" }}
                  >
                    <li>
                      <label>HASHTAGS REGISTERED :</label>
                      <span>{parseInt(htagreg).toLocaleString()}</span>
                    </li>
                    <li>
                      <label>HASHTAGS STAKED :</label>
                      <span>{parseInt(stakecount).toLocaleString()}</span>
                    </li>
                    <li>
                      <label>TAG MINERS :</label>
                      <span>{parseInt(htaghold - 1).toLocaleString()}</span>
                    </li>
                    <li>
                      <label>HOURLY TAG SUPPLY :</label>
                      <span>2100 TAGS</span>
                    </li>
                    <li>
                      <label>EST. HOURLY YIELD :</label>
                      <span>
                        {numFormatter(parseFloat(2100 / stakecount).toFixed(2))}{" "}
                        TAG /{" "}
                        {state.loading
                          ? "0"
                          : numFormatter(
                              parseFloat(
                                (2100 / stakecount) * state.data[0].TAG_USD
                              ).toFixed(4)
                            )}{" "}
                        USD
                      </span>
                    </li>
                    <li>
                      <label>EST. DAILY YIELD :</label>
                      <span>
                        {numFormatter(
                          parseFloat((2100 / stakecount) * 24).toFixed(2)
                        )}{" "}
                        TAG /{" "}
                        {state.loading
                          ? "0"
                          : numFormatter(
                              parseFloat(
                                (2100 / stakecount) * 24 * state.data[0].TAG_USD
                              ).toFixed(4)
                            )}{" "}
                        USD
                      </span>
                    </li>
                    <li>
                      <label>EST. MONTHLY YIELD :</label>
                      <span>
                        {numFormatter(
                          parseFloat((2100 / stakecount) * 24 * 30).toFixed(2)
                        )}{" "}
                        TAG /{" "}
                        {state.loading
                          ? "0"
                          : numFormatter(
                              parseFloat(
                                (2100 / stakecount) *
                                  24 *
                                  30 *
                                  state.data[0].TAG_USD
                              ).toFixed(4)
                            )}{" "}
                        USD
                      </span>
                    </li>
                    <li>
                      <label>EST. YEARLY YIELD :</label>
                      <span>
                        {numFormatter(
                          parseFloat(
                            (2100 / stakecount) * 24 * 30 * 12
                          ).toFixed(2)
                        )}{" "}
                        TAG /{" "}
                        {state.loading
                          ? "0"
                          : numFormatter(
                              parseFloat(
                                (2100 / stakecount) *
                                  24 *
                                  30 *
                                  12 *
                                  state.data[0].TAG_USD
                              ).toFixed(4)
                            )}{" "}
                        USD
                      </span>
                    </li>
                    {/* <li><label>UNMINTED :</label><span>{parseInt(100000-htagreg).toLocaleString()}</span></li> */}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Datarow;
