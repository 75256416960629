import React from "react";
import logo from "../app/logo/tagcoin-logo.png";

const Maintenance = () => {
  return (
    // <div
    //   style={{
    //     textAlign: "center",
    //     paddingTop: "175px",
    //     background: "#3081ce",
    //     height: "100vh",
    //   }}
    // >
    //   <img src={logo} style={{ width: "90%", maxWidth: "400px" }} />
    //   <h3 style={{ fontSize: "3em", color: "white", marginTop: "80px" }}>
    //     Tagcoin.io is currently down for maintenance
    //   </h3>
    //   <p style={{ fontSize: "1.5em", color: "#e6e6e6", marginTop: "35px" }}>
    //     We'll be back shortly
    //   </p>
    // </div>

    <div
      style={{
        paddingTop: 135,
        paddingLeft: 20,
        paddingRight: 20,
        background: "#3081ce",
        height: "100vh",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={logo} alt="" style={{ width: "90%", maxWidth: "375px" }} />
      </div>
      <div
        style={{ display: "grid", justifyContent: "center", marginTop: 150 }}
      >
        <p style={{ color: "white", fontSize: 32, fontWeight: "700" }}>
          We'll be back soon !
        </p>
        <p style={{ color: "white", fontSize: 24 }}>
          Sorry for the inconvenience but we're performing some maintenance at
          the moment. We'll be back shortly.
        </p>
      </div>
    </div>
  );
};

export default Maintenance;
