import React, { useState, useEffect, useContext } from "react";
import { CommonDataContext } from "./CommonDataContext";

const data = [
  { id: 1, label: "BUSD", icon: "busdiconlpt" },
  { id: 2, label: "BNB", icon: "bnbiconlpt" },
  { id: 3, label: "EGOLD", icon: "egoldicon" },
  { id: 4, label: "TMOON", icon: "tmoonicon" },
  { id: 5, label: "STAG", icon: "stagicon" },
];

const Dropdownfrom = () => {
  const valContext = useContext(CommonDataContext);
  const { from, setFrom, to, setTo } = valContext;

  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState(data);
  const [selectedItem, setSelectedItem] = useState(1);

  useEffect(() => {
    if (to == from) {
      setSelectedItem(0);
      setFrom(null);
    }
  }, [to]);

  useEffect(() => {
    if (from != null) {
      let x = items.find((item) => item.label == from).id;
      setSelectedItem(x);
    }
  }, [from]);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (id) => {
    if (id) {
      let x = items.find((item) => item.id == id).label;
      setSelectedItem(id);
      setOpen(!isOpen);
      setFrom(x);
    }
  };

  return (
    <div className="dropdownlpt">
      <div className="dropdown-header" onClick={toggleDropdown}>
        {selectedItem ? (
          selectedItem !== 3 ? (
            <>
              <span className="tagiconlpt"></span>
              <span
                className={items.find((item) => item.id == selectedItem).icon}
              ></span>
              <span>{items.find((item) => item.id == selectedItem).label}</span>
              <span>- TAG</span>
            </>
          ) : (
            <>
              <span className="egoldiconlpt"></span>
              <span className="busdicon"></span>
              <span style={{ fontSize: "11px" }}>
                {items.find((item) => item.id == selectedItem).label}&nbsp;
              </span>
              <span style={{ fontSize: "11px" }}> - BUSD</span>
            </>
          )
        ) : (
          <span style={{ margin: "0 auto" }}>Select</span>
        )}
        <svg height="15px" viewBox="0 0 24 24" width="12px" fill="#000000">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>
      </div>
      <div className={`dropdown-body ${isOpen && "open"}`}>
        {items.map((item) =>
          item.label == "EGOLD" ? (
            <div
              className="dropdown-item"
              onClick={(e) => handleItemClick(e.target.id)}
              id={item.id}
            >
              <span
                className="egoldiconlpt"
                alt=""
                onClick={(e) => handleItemClick(e.target.id)}
                id={item.id}
              />
              <span
                className="busdicon"
                alt=""
                onClick={(e) => handleItemClick(e.target.id)}
                id={item.id}
              />
              {item.label}
              -BUSD
              <span
                className={`dropdown-item-dot ${
                  item.id == selectedItem && "selected"
                }`}
              >
                •{" "}
              </span>
            </div>
          ) : (
            <div
              className="dropdown-item"
              onClick={(e) => handleItemClick(e.target.id)}
              id={item.id}
            >
              <span
                className="tagiconlpt"
                alt=""
                onClick={(e) => handleItemClick(e.target.id)}
                id={item.id}
              />
              <span
                className={item.icon}
                alt=""
                onClick={(e) => handleItemClick(e.target.id)}
                id={item.id}
              />
              {item.label}
              -TAG
              <span
                className={`dropdown-item-dot ${
                  item.id == selectedItem && "selected"
                }`}
              >
                •{" "}
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Dropdownfrom;
