import React from "react";
import { useWallet, UseWalletProvider } from "use-wallet";

function Footer() {
  async function addTokenFunction() {
    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: "0x717fb7b6d0c3d7f1421cc60260412558283a6ae5",
            symbol: "TAG",
            decimals: 18,
            image: "https://tagcoin.io/static/media/favicon.ad65ef45.png",
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("HelloWorld Coin has not been added");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="copyright" style={{ backgroundColor: "#e1e1e1" }}>
      <div className="container">
        <div className="row">
          <div
            class="col-lg-12"
            style={{ margin: "10px auto", fontSize: "12px" }}
          >
            Tagcoin Token Address : 0x717fb7b6d0c3d7f1421cc60260412558283a6ae5{" "}
            <br />
            <p style={{ marginTop: "10px" }}>
              <button
                style={{ marginRight: "5px" }}
                class="btn btn-sm btn-primary"
                onClick={() => {
                  navigator.clipboard.writeText(
                    "0x717fb7b6d0c3d7f1421cc60260412558283a6ae5"
                  );
                }}
              >
                Copy
              </button>
              <button class="btn btn-sm btn-primary" onClick={addTokenFunction}>
                Add to MetaMask
              </button>
            </p>
          </div>
          <div className="col-lg-12">
            <h6 style={{ color: "#3081ce" }}>
              To learn more about the project please visit{" "}
              <a
                target="_blank"
                href="https://tagprotocol.com/"
                style={{ color: "#3081ce" }}
              >
                www.tagprotocol.com
              </a>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
