import React, { useState, useEffect, useContext } from "react";
import { CommonDataContext } from "./CommonDataContext";

const data = [
  { id: 1, label: "BNB", icon: "bnbicon" },
  { id: 2, label: "BUSD", icon: "busdicon" },
  { id: 3, label: "TAG", icon: "tagicon" },
  { id: 4, label: "EGOLD", icon: "egoldicon" },
  { id: 5, label: "TMOON", icon: "tmoonicon" },
  { id: 6, label: "STAG", icon: "stagicon" },
];

const Dropdownto = () => {
  const valContext = useContext(CommonDataContext);
  const { from, setFrom, to, setTo } = valContext;

  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState(data);
  const [selectedItem, setSelectedItem] = useState(3);

  useEffect(() => {
    if (from == to) {
      setSelectedItem(0);
      setTo(null);
    }
    if ((to == "TMOON" || to == "STAG") && from != "TAG") {
      setTo(null);
      setSelectedItem(0);
    }
    if (to == "EGOLD" && from != "BUSD") {
      setTo(null);
      setSelectedItem(0);
    }
  }, [from]);

  useEffect(() => {
    if (to != null) {
      let x = items.find((item) => item.label == to).id;
      setSelectedItem(x);
    }
    if (to == "TMOON" || to == "STAG") {
      setFrom("TAG");
    }
    if (to == "EGOLD") {
      setFrom("BUSD");
    }
  }, [to]);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (id) => {
    if (id) {
      let x = items.find((item) => item.id == id).label;
      setSelectedItem(id);
      setOpen(!isOpen);
      setTo(x);
    }
  };

  return (
    <div className="dropdown">
      <div className="dropdown-header" onClick={toggleDropdown}>
        {selectedItem ? (
          <>
            <span
              className={items.find((item) => item.id == selectedItem).icon}
            ></span>
            <span>{items.find((item) => item.id == selectedItem).label}</span>
          </>
        ) : (
          <span style={{ margin: "0 auto" }}>Select</span>
        )}
        <svg height="15px" viewBox="0 0 24 24" width="12px" fill="#000000">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>
      </div>
      <div className={`dropdown-body ${isOpen && "open"}`}>
        {items.map((item) => (
          <div
            className="dropdown-item"
            onClick={(e) => handleItemClick(e.target.id)}
            id={item.id}
          >
            <span
              className={item.icon}
              alt=""
              onClick={(e) => handleItemClick(e.target.id)}
              id={item.id}
            />
            {item.label}
            <span
              className={`dropdown-item-dot ${
                item.id == selectedItem && "selected"
              }`}
            >
              •{" "}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdownto;
