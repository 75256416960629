import React, { useState, useEffect, useContext } from "react";
import { useWallet, UseWalletProvider } from "use-wallet";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import TradeData from "../components/TradeData";
import LiquidityData from "../components/LiquidityData";
import Datarow from "../components/Datarow";
import Burnrow from "../components/Burnrow";
import StatRow from "../components/StatRow";
import Dropdownfrom from "../components/Dropdownfrom";
import Dropdownto from "../components/Dropdownto";
import Footer from "../components/Footer";
import { CommonDataContext } from "./CommonDataContext";
import { store } from "react-notifications-component";
import ls, { set } from "local-storage";
import abiVal from "../data/abi.json";
import LoginCheck from "../components/LoginCheck";

const Web3 = require("web3");
const web3 = new Web3("https://bsc-dataseed.binance.org/");
const CONTRACT_ADDRESS = abiVal.pswapcontract;
const CONTRACT_ABI = abiVal.pswapabi;

const FACTORY_ADDRESS = abiVal.factorycontract;
const FACTORY_ABI = abiVal.factoryabi;

const TAG_ADDRESS = abiVal.tagcontract;
const TMOON_ADDRESS = abiVal.tmooncontract;
const EGOLD_ADDRESS = abiVal.egoldcontract;
const STAG_ADDRESS = abiVal.stagcontract;
const TAG_ABI = abiVal.tagabi;

const BUSD_ADDRESS = abiVal.firsttokencontract;
const BUSD_ABI = abiVal.firsttokenabi;

const PAIR_ABI = abiVal.pairabi;

localStorage.setItem("flag", "false");
localStorage.setItem("ltype", "0");

function LiquidityPan() {
  const valContext = useContext(CommonDataContext);
  const {
    runCommand,
    setrunCommand,
    state,
    setState,
    liqstate,
    setLiqstate,
    marketstate,
    setMarketstate,
    marketval,
    setMarketval,
    tagholders,
    setTagholders,
    htagreg,
    htaghold,
    setHtaghold,
    setHtagreg,
    stakecount,
    setStakecount,
    tradestate,
    setTradestate,
    from,
    setFrom,
    to,
    setTo,
    setUnmintedtoken,
  } = valContext;

  const wallet = useWallet();
  let history = useHistory();
  const webb3 = new Web3(wallet.ethereum);
  const contractInstance = new webb3.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );
  const factoryInstance = new webb3.eth.Contract(FACTORY_ABI, FACTORY_ADDRESS);

  const tagcontractInstance = new webb3.eth.Contract(TAG_ABI, TAG_ADDRESS);
  const busdcontractInstance = new webb3.eth.Contract(BUSD_ABI, BUSD_ADDRESS);

  const factoryInstancew3 = new web3.eth.Contract(FACTORY_ABI, FACTORY_ADDRESS);

  const [fromval, setFromval] = useState("");
  const [toval, setToval] = useState("");
  const [tagbalance, setTagbal] = useState("");
  const [frombalance, setFrombal] = useState("");
  const [tobalance, setTobal] = useState("");
  const [pairbalance, setPairbal] = useState("");
  const [pairval, setPairval] = useState("");
  const [loadinggadd, setLoadinggadd] = useState(false);
  const [loadinggaptok, setLoadinggaptok] = useState(false);
  const [loadinggaplpt, setLoadinggaplpt] = useState(false);
  const [loadinggaptag, setLoadinggaptag] = useState(false);
  const [loadinggrem, setLoadinggrem] = useState(false);
  const [tokenapp, setTokenapp] = useState(true); //token approve button enabled
  const [tagapp, setTagapp] = useState(true); //tag approve button enabled
  const [lptapp, setLptapp] = useState(true); //tag approve button enabled

  //onload
  useEffect(() => {
    getTokbal();
  }, []);

  //on from or to change from dropdown
  useEffect(() => {
    handlefromChange(fromval);
    getTokbal();
  }, [from, to]);

  //on wallet connect or disconnect
  useEffect(() => {
    if (runCommand == 0) {
      setFrombal("0");
      setTobal("0");
      setPairbal("0");
      setTagbal("0");
    } else getTokbal();
  }, [runCommand]);

  //initial load for trade and market data
  useEffect(() => {
    setState((state) => ({ data: state.data, error: false, loading: true }));
    const url = "https://tagdev.info/v1/summary/getLatestTrades/20";
    const liqurl = "https://tagdev.info/v1/summary/getLiq/20";
    const liqurlper =
      "https://tagdev.info/v1/summary/getLiq/20/" + ls.get("usradd");
    const url2 = "https://tagdev.info/v1/summary/get24MarketStat";
    const regurl = "https://tagcoin-api.tagdev.info/v1/summary/";
    const marketurl = "https://tagdev.info/v1/summary/";
    const latestblockurl =
      "https://ledger-trans.tagdev.info/v1/summary/getLastIndexed/last";
    const unmintedurl = "https://ledger.tagdev.info/v1/summary/getUnminted";
    console.log("fetching");

    //fetch trade data
    fetch(url)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) =>
        setState({ data: newData, error: false, loading: false })
      )
      .catch(function (error) {
        console.log(error);
      });

    //fetch liquidity data
    fetch(ls.get("ltype") == 0 ? liqurl : liqurlper)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) =>
        setLiqstate({ data: newData, error: false, loading: false })
      )
      .catch(function (error) {
        console.log(error);
      });

    //fetch 24 hour market data
    fetch(url2)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) =>
        setMarketstate({ data: newData, error: false, loading: false })
      )
      .catch(function (error) {
        console.log(error);
      });

    //fetch registered count data
    fetch(regurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setHtagreg(data.hashtags);
        setHtaghold(data.wallets);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //fetch supply and liquidity data
    fetch(marketurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setMarketval({
          supply: data.supply,
          totaltag: data.pools.totalTag,
          totalusd: data.pools.totalUsd,
          lockedsupply: data.lockedSupply,
        });
        setTagholders(data.holders);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //fetch staking count data
    fetch(latestblockurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return fetch(
          "https://ledger-trans.tagdev.info/v1/summary/getSummary/" + data.id
        );
      })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setStakecount(data.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //fetch unminted tokens

    fetch(unmintedurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setUnmintedtoken(data.unminted);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //load with interval
    const interval = setInterval(() => {
      const url = "https://tagdev.info/v1/summary/getLatestTrades/20";
      const url2 = "https://tagdev.info/v1/summary/get24MarketStat";

      console.log("fetching");
      fetch(url)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setState({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });

      //fetch liquidity data
      fetch(ls.get("ltype") == 0 ? liqurl : liqurlper)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setLiqstate({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });

      fetch(url2)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setMarketstate({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });
    }, 15000);

    const interval2 = setInterval(() => {
      //fetch registered count data
      fetch(regurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setHtagreg(data.hashtags);
          setHtaghold(data.wallets);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });

      //fetch supply and liquidity data
      fetch(marketurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setMarketval({
            supply: data.supply,
            totaltag: data.pools.totalTag,
            totalusd: data.pools.totalUsd,
            lockedsupply: data.lockedSupply,
          });
          setTagholders(data.holders);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });

      //fetch staking count data
      fetch(latestblockurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          return fetch(
            "https://ledger-trans.tagdev.info/v1/summary/getSummary/" + data.id
          );
        })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setStakecount(data.count);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });

      //fetch unminted tokens

      fetch(unmintedurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setUnmintedtoken(data.unminted);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });
    }, 60000);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, []);

  //to not round values while showing balance
  function noround(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  //sets value for token balance and lp token balance
  const getTokbal = async () => {
    const w3 = new Web3("https://bsc-dataseed.binance.org/");
    const tagcontractInstanceto = new w3.eth.Contract(TAG_ABI, TAG_ADDRESS);
    const busdcontractInstanceto = new w3.eth.Contract(BUSD_ABI, BUSD_ADDRESS);
    const tmooncontractInstanceto = new w3.eth.Contract(TAG_ABI, TMOON_ADDRESS);
    const egoldcontractInstanceto = new w3.eth.Contract(TAG_ABI, EGOLD_ADDRESS);
    const stagcontractInstanceto = new w3.eth.Contract(TAG_ABI, STAG_ADDRESS);

    //set From balance
    if (from == "BNB") {
      setFrombal("BNBval");
    } else if (from == "BUSD") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await busdcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setFrombal(res);
            }
          });
      }
    } else if (from == "TMOON") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await tmooncontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setFrombal(res);
            }
          });
      }
    } else if (from == "EGOLD") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await egoldcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setFrombal(res);
            }
          });
      }
    } else if (from == "STAG") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await stagcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setFrombal(res);
            }
          });
      }
    } else if (from == "TAG") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await tagcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setFrombal(res);
            }
          });
      }
    } else if (from == null) {
      setFrombal("0");
    }

    //set To balance
    if (to == "BNB") {
      setTobal("BNBval");
    } else if (to == "BUSD") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await busdcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              console.log("busdbal", res);
              setTobal(res);
            }
          });
      }
    } else if (to == "TMOON") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await tmooncontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              console.log("busdbal", res);
              setTobal(res);
            }
          });
      }
    } else if (to == "EGOLD") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await egoldcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              console.log("busdbal", res);
              setTobal(res);
            }
          });
      }
    } else if (to == "STAG") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await stagcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              console.log("busdbal", res);
              setTobal(res);
            }
          });
      }
    } else if (to == "TAG") {
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(8000);
          localStorage.setItem("flag", "false");
        }
        await tagcontractInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              setTobal(res);
            }
          });
      }
    } else if (to == null) {
      setTobal("0");
    }

    //set lp-token balance
    if (from == null || to == null) {
      setPairbal("0");
    } else {
      const tagadd = TAG_ADDRESS;
      if (from == "BNB") {
        var tokencaddress1 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
      } else if (from == "BUSD") {
        var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (from == "TAG") {
        var tokencaddress1 = tagadd;
      } else if (from == "TMOON") {
        var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (from == "STAG") {
        var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (from == "EGOLD") {
        var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
      }

      if (to == "BNB") {
        var tokencaddress2 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
      } else if (to == "BUSD") {
        var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (to == "TAG") {
        var tokencaddress2 = tagadd;
      } else if (to == "TMOON") {
        var tokencaddress2 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (to == "STAG") {
        var tokencaddress2 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (to == "EGOLD") {
        var tokencaddress2 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
      }

      let PAIR_ADDRESS;
      await factoryInstancew3.methods
        .getPair(tokencaddress1, tokencaddress2)
        .call(function (err, res) {
          if (err) {
            console.log("An error occured", err);
            return;
          }
          console.log("here pair addresss- ", res);
          PAIR_ADDRESS = res;
        });

      const pairInstanceto = new w3.eth.Contract(PAIR_ABI, PAIR_ADDRESS);
      if (ls.get("usradd")) {
        await pairInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              console.log("tokbal - ", res);
              setPairbal(res);
            }
          });
      }
    }

    //get Tag balance
    if (ls.get("usradd")) {
      await tagcontractInstanceto.methods
        .balanceOf(ls.get("usradd"))
        .call({}, function (error, res) {
          if (error != null) {
            console.log(error);
            return;
          } else {
            console.log("tagbal - ", res);
            setTagbal(res);
          }
        });
    }
  };

  //for setting lp token balance
  async function handlepairChange(val) {
    setPairval(val);
  }

  //autocomplete 1st input value
  async function handlefromChange(val) {
    if (from != null && to != null) {
      if (isNaN(val) || val == "") {
        setFromval("");
        setToval("");
      } else if (from != null && to != null) {
        setFromval(val);
        const tagadd = TAG_ADDRESS;
        if (from == "BNB") {
          var tokencaddress1 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
        } else if (from == "BUSD") {
          var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
        } else if (from == "TAG") {
          var tokencaddress1 = tagadd;
        } else if (from == "TMOON") {
          var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
        } else if (from == "STAG") {
          var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
        } else if (from == "EGOLD") {
          var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
        }

        if (to == "BNB") {
          var tokencaddress2 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
        } else if (to == "BUSD") {
          var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
        } else if (to == "TAG") {
          var tokencaddress2 = tagadd;
        } else if (to == "TMOON") {
          var tokencaddress2 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
        } else if (to == "STAG") {
          var tokencaddress2 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
        } else if (to == "EGOLD") {
          var tokencaddress2 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
        }

        let PAIR_ADDRESS;
        await factoryInstance.methods
          .getPair(tokencaddress1, tokencaddress2)
          .call(function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            }
            PAIR_ADDRESS = res;
          });

        const pairInstancew3 = new web3.eth.Contract(PAIR_ABI, PAIR_ADDRESS);

        await pairInstancew3.methods
          .getReserves()
          .call(async function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            }
            await pairInstancew3.methods.token0().call(function (err, ress) {
              if (err) {
                console.log("An error occured", err);
                return;
              }
              if (ress == tokencaddress1)
                setToval(
                  parseFloat(
                    (webb3.utils.fromWei(res[1]) /
                      webb3.utils.fromWei(res[0])) *
                      val
                  ).toFixed(6)
                );
              else
                setToval(
                  parseFloat(
                    (webb3.utils.fromWei(res[0]) /
                      webb3.utils.fromWei(res[1])) *
                      val
                  ).toFixed(6)
                );
            });
          });
      }
    }
  }

  //autocompletes From input field based on value on To field
  async function handletoChange(val) {
    if (from != null && to != null) {
      if (isNaN(val) || val == "") {
        setFromval("");
        setToval("");
      } else if (from != null && to != null) {
        setToval(val);
        const tagadd = TAG_ADDRESS;

        if (from == "BNB") {
          var tokencaddress1 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
        } else if (from == "BUSD") {
          var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
        } else if (from == "TAG") {
          var tokencaddress1 = tagadd;
        } else if (from == "TMOON") {
          var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
        } else if (from == "STAG") {
          var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
        } else if (from == "EGOLD") {
          var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
        }

        if (to == "BNB") {
          var tokencaddress2 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
        } else if (to == "BUSD") {
          var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
        } else if (to == "TAG") {
          var tokencaddress2 = tagadd;
        } else if (to == "TMOON") {
          var tokencaddress2 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
        } else if (to == "STAG") {
          var tokencaddress2 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
        } else if (to == "EGOLD") {
          var tokencaddress2 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
        }

        let PAIR_ADDRESS;
        await factoryInstance.methods
          .getPair(tokencaddress1, tokencaddress2)
          .call(function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            }
            PAIR_ADDRESS = res;
          });

        const pairInstancew3 = new web3.eth.Contract(PAIR_ABI, PAIR_ADDRESS);

        await pairInstancew3.methods
          .getReserves()
          .call(async function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            }
            await pairInstancew3.methods.token0().call(function (err, ress) {
              if (err) {
                console.log("An error occured", err);
                return;
              }
              if (ress == tokencaddress1)
                setFromval(
                  parseFloat(
                    (webb3.utils.fromWei(res[0]) /
                      webb3.utils.fromWei(res[1])) *
                      val
                  ).toFixed(6)
                );
              else
                setFromval(
                  parseFloat(
                    (webb3.utils.fromWei(res[1]) /
                      webb3.utils.fromWei(res[0])) *
                      val
                  ).toFixed(6)
                );
            });
          });
      }
    }
  }

  //To approve Token
  async function approveToken() {
    if (fromval != "" && toval != "") {
      setLoadinggaptok(true);
      let gaslim = await webb3.eth.estimateGas({ from: wallet.account });
      let gaspri = await webb3.eth.getGasPrice();
      let fromconaddr = CONTRACT_ADDRESS;
      let appvalue;
      if (from == "TMOON" || to == "TMOON") {
        if (from == "TMOON") {
          appvalue = fromval;
        } else if (to == "TMOON") {
          appvalue = toval;
        }
        var tokencontractInstance = new webb3.eth.Contract(
          TAG_ABI,
          TMOON_ADDRESS
        );
      } else if (from == "STAG" || to == "STAG") {
        if (from == "STAG") {
          appvalue = fromval;
        } else if (to == "STAG") {
          appvalue = toval;
        }
        var tokencontractInstance = new webb3.eth.Contract(
          TAG_ABI,
          STAG_ADDRESS
        );
      } else if (from == "EGOLD" || to == "EGOLD") {
        if (from == "EGOLD") {
          appvalue = fromval;
        } else if (to == "EGOLD") {
          appvalue = toval;
        }
        var tokencontractInstance = new webb3.eth.Contract(
          TAG_ABI,
          EGOLD_ADDRESS
        );
      }

      await tokencontractInstance.methods
        .approve(fromconaddr, webb3.utils.toWei(appvalue))
        .send({
          from: wallet.account,
          gasLimit: gaslim,
          gasPrice: gaspri,
        })
        .on("receipt", async function (hash) {
          setTokenapp(false);
          setLoadinggaptok(false);

          store.addNotification({
            id: "approvee-complete",
            title: "Approved",
            message: "Transaction was approved",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, receipt) {
          console.log("error");
          setLoadinggadd(false);
          store.addNotification({
            id: "approvee-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    }
  }

  //To approve Tag
  async function approveTag() {
    if (fromval != "" && toval != "") {
      setLoadinggaptag(true);
      let gaslim = await webb3.eth.estimateGas({ from: wallet.account });
      let gaspri = await webb3.eth.getGasPrice();
      let fromconaddr = CONTRACT_ADDRESS;
      let appvalue;
      if (from == "TAG") {
        appvalue = fromval;
      } else if (to == "TAG") {
        appvalue = toval;
      }
      await tagcontractInstance.methods
        .approve(fromconaddr, webb3.utils.toWei(appvalue))
        .send({
          from: wallet.account,
          gasLimit: gaslim,
          gasPrice: gaspri,
        })
        .on("receipt", async function (hash) {
          setTagapp(false);
          setLoadinggaptag(false);

          store.addNotification({
            id: "approvee-complete",
            title: "Approved",
            message: "Transaction was approved",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, receipt) {
          console.log("error");
          setLoadinggadd(false);
          store.addNotification({
            id: "approvee-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    }
  }

  //To approve BUSD
  async function approveBusd() {
    if (fromval != "" && toval != "") {
      setLoadinggaptok(true);
      let gaslim = await webb3.eth.estimateGas({ from: wallet.account });
      let gaspri = await webb3.eth.getGasPrice();
      let fromconaddr = CONTRACT_ADDRESS;
      let appvalue;
      if (from == "BUSD") {
        appvalue = fromval;
      } else if (to == "BUSD") {
        appvalue = toval;
      }
      await busdcontractInstance.methods
        .approve(fromconaddr, webb3.utils.toWei(appvalue))
        .send({
          from: wallet.account,
          gasLimit: gaslim,
          gasPrice: gaspri,
        })
        .on("receipt", async function (hash) {
          setTokenapp(false);
          setLoadinggaptok(false);

          store.addNotification({
            id: "approvee-complete",
            title: "Approved",
            message: "Transaction was approved",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, receipt) {
          console.log("error");
          setLoadinggadd(false);
          store.addNotification({
            id: "approvee-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    }
  }

  //to add liquidity
  async function addliqToken() {
    setLoadinggadd(true);

    let endtime = Math.floor(Date.now() / 1000) + 300;
    const tagadd = TAG_ADDRESS;

    let fromconaddr = CONTRACT_ADDRESS;

    if (from == "BNB" || to == "BNB") {
      //handles BNB-TAG | BNB-BUSD liquidity pair. Any token with BNB
      if (from == "BNB") {
        var appval = toval;
        var bnbval = fromval;
        var mintok = parseFloat(0.95 * toval)
          .toFixed(16)
          .toString();
        var minbnb = parseFloat(0.95 * fromval)
          .toFixed(16)
          .toString();
      } else if (to == "BNB") {
        var appval = fromval;
        var bnbval = toval;
        var mintok = parseFloat(0.95 * fromval)
          .toFixed(16)
          .toString();
        var minbnb = parseFloat(0.95 * toval)
          .toFixed(16)
          .toString();
      }
      let tokencontractinstance;
      if (from == "BUSD" || to == "BUSD") {
        tokencontractinstance = busdcontractInstance;
        var tokconaddr = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (from == "TAG" || to == "TAG") {
        tokencontractinstance = tagcontractInstance;
        var tokconaddr = tagadd;
      }

      await tokencontractinstance.methods
        .approve(fromconaddr, webb3.utils.toWei(appval))
        .send({
          from: wallet.account,
        })
        .on("receipt", async function (hash) {
          console.log("Approved");

          store.addNotification({
            id: "approvee-complete",
            title: "Approved",
            message: "Transaction was approved",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
          await contractInstance.methods
            .addLiquidityETH(
              tokconaddr,
              webb3.utils.toWei(appval).toString(),
              webb3.utils.toWei(mintok, "ether"),
              webb3.utils.toWei(minbnb, "ether"),
              wallet.account,
              endtime
            )
            .send({
              from: wallet.account,
              gasLimit: 300000,
              gasPrice: webb3.utils.toWei("10", "gwei"),
              value: webb3.utils.toWei(bnbval),
            })
            .on("receipt", async function (hashh) {
              console.log("Liquidity added");
              localStorage.setItem("flag", "true");
              await getTokbal();
              setLoadinggadd(false);

              store.addNotification({
                id: "addliq-complete",
                title: "SUCCESS",
                message: "Liquidity was added successfully",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                },
              });
            })
            .on("error", function (error, hashh) {
              console.log("error");
              setLoadinggadd(false);
              store.addNotification({
                id: "addliq-error",
                title: "Error",
                message: error.message,
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 8000,
                },
              });
            });
        })
        .on("error", function (error, receipt) {
          console.log("error");
          setLoadinggadd(false);
          store.addNotification({
            id: "approvee-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    } //handles all other liquidity pairs except BNB
    else {
      if (from == "TAG") {
        var tokencaddress1 = tagadd;
        if (to == "BUSD") {
          var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
        } else if (to == "TMOON") {
          var tokencaddress2 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
        } else if (to == "STAG") {
          var tokencaddress2 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
        }
      } else {
        var tokencaddress2 = tagadd;
        if (from == "BUSD") {
          var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
          if (to == "EGOLD") {
            var tokencaddress2 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
          }
        } else if (from == "TMOON") {
          var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
        } else if (from == "STAG") {
          var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
        } else if (from == "EGOLD") {
          var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
          tokencaddress2 = BUSD_ADDRESS;
        }
      }
      var minfrom = parseFloat(0.95 * fromval)
        .toFixed(16)
        .toString();
      var minto = parseFloat(0.95 * toval)
        .toFixed(16)
        .toString();
      await contractInstance.methods
        .addLiquidity(
          tokencaddress1,
          tokencaddress2,
          webb3.utils.toWei(fromval).toString(),
          webb3.utils.toWei(toval).toString(),
          webb3.utils.toWei(minfrom, "ether"),
          webb3.utils.toWei(minto, "ether"),
          wallet.account,
          endtime
        )
        .send({
          from: wallet.account,
          gasLimit: 300000,
          gasPrice: webb3.utils.toWei("10", "gwei"),
        })
        .on("receipt", async function (hashh) {
          console.log("Liquidity added");
          localStorage.setItem("flag", "true");
          await getTokbal();
          setLoadinggadd(false);
          setTokenapp(true);
          setTagapp(true);

          store.addNotification({
            id: "addliq-complete",
            title: "SUCCESS",
            message: "Liquidity was added successfully",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, hashh) {
          console.log("error");
          setLoadinggadd(false);
          store.addNotification({
            id: "addliq-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    }
  }

  //To approve LP Token
  async function approveLpToken() {
    if (pairval != "") {
      setLoadinggaplpt(true);
      let gaslim = await webb3.eth.estimateGas({ from: wallet.account });
      let gaspri = await webb3.eth.getGasPrice();
      let fromconaddr = CONTRACT_ADDRESS;
      const tagadd = TAG_ADDRESS;

      if (from == "BNB") {
        var tokencaddress1 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
      } else if (from == "BUSD") {
        var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (from == "TAG") {
        var tokencaddress1 = tagadd;
      } else if (from == "TMOON") {
        var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (from == "STAG") {
        var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (from == "EGOLD") {
        var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
      }

      if (to == "BNB") {
        var tokencaddress2 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
      } else if (to == "BUSD") {
        var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (to == "TAG") {
        var tokencaddress2 = tagadd;
      } else if (to == "TMOON") {
        var tokencaddress2 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (to == "STAG") {
        var tokencaddress2 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (to == "EGOLD") {
        var tokencaddress2 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
      }

      let PAIR_ADDRESS;
      await factoryInstancew3.methods
        .getPair(tokencaddress1, tokencaddress2)
        .call(function (err, res) {
          if (err) {
            console.log("An error occured", err);
            return;
          }
          PAIR_ADDRESS = res;
        });
      const pairInstanceto = new webb3.eth.Contract(PAIR_ABI, PAIR_ADDRESS);

      var appval = pairval;

      await pairInstanceto.methods
        .approve(fromconaddr, webb3.utils.toWei(appval))
        .send({
          from: wallet.account,
          gasLimit: gaslim,
          gasPrice: gaspri,
        })
        .on("receipt", async function (hash) {
          console.log("Approved");
          setLptapp(false);
          setLoadinggaplpt(false);

          store.addNotification({
            id: "approvee-complete",
            title: "Approved",
            message: "Transaction was approved",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, receipt) {
          console.log("error");
          setLoadinggaplpt(false);
          store.addNotification({
            id: "approvee-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    }
  }

  //To remove liquidity
  async function remliqToken() {
    setLoadinggrem(true);
    let endtime = Math.floor(Date.now() / 1000) + 600;
    const tagadd = TAG_ADDRESS;

    if (from == "BNB" || to == "BNB") {
      var tokencaddress1 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";

      if (from == "BUSD" || to == "BUSD") {
        var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (from == "TAG" || to == "TAG") {
        var tokencaddress2 = tagadd;
      }

      await contractInstance.methods
        .removeLiquidityETH(
          tokencaddress2,
          webb3.utils.toWei(pairval).toString(),
          0,
          0,
          wallet.account,
          endtime
        )
        .send({
          from: wallet.account,
        })
        .on("receipt", async function (hash) {
          localStorage.setItem("flag", "true");
          await getTokbal();
          setLoadinggrem(false);
          setLptapp(true);
          store.addNotification({
            id: "remliq-complete",
            title: "REMOVED",
            message: "Liquidity was removed successfully",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, hash) {
          console.log("error");
          setLoadinggrem(false);
          store.addNotification({
            id: "remliq-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    } else {
      var tokencaddress2 = tagadd;
      if (from == "BUSD" || to == "BUSD") {
        var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
        if (from == "EGOLD" || to == "EGOLD") {
          var tokencaddress2 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
        }
      } else if (from == "TMOON" || to == "TMOON") {
        var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (from == "STAG" || to == "STAG") {
        var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (from == "EGOLD" || to == "EGOLD") {
        var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
        tokencaddress2 = BUSD_ADDRESS;
      }

      await contractInstance.methods
        .removeLiquidity(
          tokencaddress1,
          tokencaddress2,
          webb3.utils.toWei(pairval).toString(),
          0,
          0,
          wallet.account,
          endtime
        )
        .send({
          from: wallet.account,
          gasLimit: 300000,
          gasPrice: webb3.utils.toWei("10", "gwei"),
        })
        .on("receipt", async function (hash) {
          localStorage.setItem("flag", "true");
          await getTokbal();
          setLoadinggrem(false);
          setLptapp(true);
          store.addNotification({
            id: "remliq-complete",
            title: "REMOVED",
            message: "Liquidity was removed successfully",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, hash) {
          console.log("error");
          setLoadinggrem(false);
          store.addNotification({
            id: "remliq-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    }
  }

  function handleMaxfrom(wall, from) {
    if (from == "BNBval") {
      var x = noround(parseFloat(web3.utils.fromWei(wall), "ether"), 8);
      setFromval(x);
      handlefromChange(x);
    } else {
      var x = noround(parseFloat(web3.utils.fromWei(from), "ether"), 8);
      setFromval(x);
      handlefromChange(x);
    }
  }

  function handleMaxto(wall, to) {
    if (to == "BNBval") {
      var x = noround(parseFloat(web3.utils.fromWei(wall), "ether"), 8);
      setToval(x);
      handletoChange(x);
    } else {
      var x = noround(parseFloat(web3.utils.fromWei(to), "ether"), 8);
      setToval(x);
      handletoChange(x);
    }
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      <LoginCheck />

      <div class="flxrow">
        {/* ----first column----- */}
        <div class="flxcolumn">
          <div className="app-content trcontent liqheight">
            <div className="tagtrade-content">
              <h3 className="heading">Tagcoin Exchange</h3>
              <h5 className="headingin">
                Tagcoin Price :{" "}
                {state.loading
                  ? "0"
                  : parseFloat(state.data[0].TAG_USD).toFixed(4) + " USD "}
                {marketstate.loading || state.loading ? (
                  "0%"
                ) : state.data[0].TAG_USD > marketstate.data[0].open_usd ? (
                  <>
                    <svg
                      height="24px"
                      viewBox="0 0 20 28"
                      width="24px"
                      fill="#30e630"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M7 14l5-5 5 5z" />
                    </svg>
                    <span className="greenlight">
                      {parseFloat(
                        ((state.data[0].TAG_USD -
                          marketstate.data[0].open_usd) /
                          marketstate.data[0].open_usd) *
                          100
                      ).toFixed(2) + "%"}
                    </span>
                  </>
                ) : (
                  <>
                    <svg
                      height="24px"
                      viewBox="0 0 20 28"
                      width="24px"
                      fill="#ff0000"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M7 10l5 5 5-5z" />
                    </svg>
                    <span className="redlight">
                      {parseFloat(
                        ((marketstate.data[0].open_usd -
                          state.data[0].TAG_USD) /
                          marketstate.data[0].open_usd) *
                          100
                      ).toFixed(2) + "%"}
                    </span>
                  </>
                )}
              </h5>
              <div>
                <center>
                  <img
                    src="https://tagprotocol.s3.us-east-1.amazonaws.com/POWERED%20BY%20PANCAKESWAP.png"
                    alt=""
                    style={{ margin: "auto" }}
                  />
                </center>
              </div>

              <div className="tagtrade"></div>
              <ul className="nav nav-tabs">
                <li className="">
                  <a
                    href=""
                    onClick={() => {
                      history.push("/swap");
                    }}
                  >
                    SWAP
                  </a>
                </li>
                <li>
                  <a href="" className="active show">
                    LIQUIDITY
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      history.push("/lpfarm");
                    }}
                  >
                    LP FARM
                  </a>
                </li>
              </ul>
            </div>
            <div
              className={
                from == "BNB" || to == "BNB"
                  ? "body bnbliqbody"
                  : "body tokenliqbody"
              }
            >
              <div className="form-container">
                <div
                  className="field-container"
                  style={{ background: "rgb(222 240 255)", padding: "12px" }}
                >
                  <label
                    className="labl"
                    htmlFor="name"
                    style={{ padding: "5px" }}
                  >
                    Input
                  </label>
                  <label
                    className="labl"
                    htmlFor="bal"
                    style={{ padding: "5px", float: "right" }}
                  >
                    Balance :{" "}
                    {noround(
                      parseFloat(
                        web3.utils.fromWei(
                          frombalance === "BNBval"
                            ? wallet.balance
                            : frombalance
                        ),
                        "ether"
                      ),
                      4
                    )}
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      id="bnb"
                      className="inp"
                      maxLength="10"
                      type="text"
                      name="fromvalue"
                      style={{
                        background: "transparent",
                        border: "0px",
                        outline: "none",
                      }}
                      placeholder="0.0"
                      onChange={(e) => handlefromChange(e.target.value)}
                      value={fromval}
                    />
                    <div
                      style={{
                        display: "inline-flex",
                        position: "absolute",
                        right: "8px",
                        zIndex: "101",
                      }}
                    >
                      <button
                        style={{
                          background: "transparent",
                          border: "0px",
                          height: "16px",
                          marginRight: "15px",
                          marginTop: "7px",
                          fontWeight: "700",
                          color: "#1e82ce",
                          cursor: "pointer",
                          outline: "0px",
                        }}
                        onClick={(e) =>
                          handleMaxfrom(wallet.balance, frombalance)
                        }
                      >
                        MAX
                      </button>
                      <Dropdownfrom />
                    </div>
                  </div>
                </div>
                <div className="downarroww" style={{ cursor: "unset" }}>
                  <svg
                    fill="#000000"
                    viewBox="0 0 24 24"
                    width="14px"
                    height="14px"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                    />
                  </svg>
                </div>
                <div
                  className="field-container sectobox"
                  style={{
                    background: "rgb(222 240 255)",
                    padding: "12px",
                    marginTop: "3%",
                  }}
                >
                  <label
                    className="labl"
                    htmlFor="name"
                    style={{ padding: "5px" }}
                  >
                    Input
                  </label>
                  <label
                    className="labl"
                    htmlFor="bal"
                    style={{ padding: "5px", float: "right" }}
                  >
                    Balance :{" "}
                    {noround(
                      parseFloat(
                        web3.utils.fromWei(
                          tobalance === "BNBval" ? wallet.balance : tobalance
                        ),
                        "ether"
                      ),
                      4
                    )}
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      id="tag"
                      className="inp"
                      maxLength="20"
                      type="text"
                      name="tovalue"
                      style={{
                        background: "transparent",
                        border: "0px",
                        outline: "none",
                      }}
                      placeholder="0.0"
                      onChange={(e) => handletoChange(e.target.value)}
                      value={toval}
                    />
                    <div
                      style={{
                        display: "inline-flex",
                        position: "absolute",
                        right: "8px",
                        zIndex: "100",
                      }}
                    >
                      <button
                        style={{
                          display: "inline-flex",
                          background: "transparent",
                          border: "0px",
                          height: "16px",
                          marginRight: "15px",
                          marginTop: "7px",
                          fontWeight: "700",
                          color: "#1e82ce",
                          cursor: "pointer",
                          outline: "0px",
                        }}
                        onClick={(e) => handleMaxto(wallet.balance, tobalance)}
                      >
                        MAX
                      </button>
                      <Dropdownto />
                    </div>
                  </div>
                </div>
                {to != "BNB" && from != "BNB" && (
                  <div className="form-container" style={{ gridColumn: "1/3" }}>
                    {(from == "BUSD" || to == "BUSD") &&
                    to != "EGOLD" &&
                    from != "EGOLD" ? (
                      <>
                        <div
                          className="field-container trloadsm"
                          style={{
                            marginTop: "7px",
                            gridColumn: "1",
                            marginRight: "3%",
                            borderRadius: "5px",
                          }}
                        >
                          <button
                            className={
                              loadinggaptok === true
                                ? "liqbutton"
                                : "liqbutton disnone"
                            }
                            onClick={approveBusd}
                            disabled={!tokenapp}
                          >
                            APPROVE BUSD
                            <Loader />
                          </button>
                        </div>
                        <div
                          className="field-container trloadsm"
                          style={{
                            marginTop: "7px",
                            gridColumn: "2/3",
                            gridRow: "1",
                            marginLeft: "3%",
                            borderRadius: "5px",
                          }}
                        >
                          <button
                            className={
                              loadinggaptag === true
                                ? "liqbutton"
                                : "liqbutton disnone"
                            }
                            onClick={approveTag}
                            disabled={!tagapp}
                          >
                            APPROVE TAG
                            <Loader />
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {(from == "TMOON" || to == "TMOON") && (
                      <>
                        <div
                          className="field-container trloadsm"
                          style={{
                            marginTop: "7px",
                            gridColumn: "1",
                            marginRight: "3%",
                            borderRadius: "5px",
                          }}
                        >
                          <button
                            className={
                              loadinggaptok === true
                                ? "liqbutton"
                                : "liqbutton disnone"
                            }
                            onClick={approveToken}
                            disabled={!tokenapp}
                          >
                            APPROVE TMOON
                            <Loader />
                          </button>
                        </div>
                        <div
                          className="field-container trloadsm"
                          style={{
                            marginTop: "7px",
                            gridColumn: "2/3",
                            gridRow: "1",
                            marginLeft: "3%",
                            borderRadius: "5px",
                          }}
                        >
                          <button
                            className={
                              loadinggaptag === true
                                ? "liqbutton"
                                : "liqbutton disnone"
                            }
                            onClick={approveTag}
                            disabled={!tagapp}
                          >
                            APPROVE TAG
                            <Loader />
                          </button>
                        </div>
                      </>
                    )}
                    {(from == "EGOLD" || to == "EGOLD") && (
                      <>
                        <div
                          className="field-container trloadsm"
                          style={{
                            marginTop: "7px",
                            gridColumn: "1",
                            marginRight: "3%",
                            borderRadius: "5px",
                          }}
                        >
                          <button
                            className={
                              loadinggaptok === true
                                ? "liqbutton"
                                : "liqbutton disnone"
                            }
                            onClick={approveToken}
                            disabled={!tokenapp}
                          >
                            APPROVE EGOLD
                            <Loader />
                          </button>
                        </div>
                        <div
                          className="field-container trloadsm"
                          style={{
                            marginTop: "7px",
                            gridColumn: "2/3",
                            gridRow: "1",
                            marginLeft: "3%",
                            borderRadius: "5px",
                          }}
                        >
                          <button
                            className={
                              loadinggaptag === true
                                ? "liqbutton"
                                : "liqbutton disnone"
                            }
                            onClick={approveBusd}
                            disabled={!tagapp}
                          >
                            APPROVE BUSD
                            <Loader />
                          </button>
                        </div>
                      </>
                    )}
                    {(from == "STAG" || to == "STAG") && (
                      <>
                        <div
                          className="field-container trloadsm"
                          style={{
                            marginTop: "7px",
                            gridColumn: "1",
                            marginRight: "3%",
                            borderRadius: "5px",
                          }}
                        >
                          <button
                            className={
                              loadinggaptok === true
                                ? "liqbutton"
                                : "liqbutton disnone"
                            }
                            onClick={approveToken}
                            disabled={!tokenapp}
                          >
                            APPROVE STAG
                            <Loader />
                          </button>
                        </div>
                        <div
                          className="field-container trloadsm"
                          style={{
                            marginTop: "7px",
                            gridColumn: "2/3",
                            gridRow: "1",
                            marginLeft: "3%",
                            borderRadius: "5px",
                          }}
                        >
                          <button
                            className={
                              loadinggaptag === true
                                ? "liqbutton"
                                : "liqbutton disnone"
                            }
                            onClick={approveTag}
                            disabled={!tagapp}
                          >
                            APPROVE TAG
                            <Loader />
                          </button>
                        </div>
                      </>
                    )}
                    <div
                      className="field-container trload"
                      style={{
                        marginTop: "3%",
                        gridColumn: "1/3",
                        borderRadius: "5px",
                      }}
                    >
                      <button
                        className={
                          loadinggadd === true
                            ? "liqbutton"
                            : "liqbutton disnone"
                        }
                        onClick={addliqToken}
                        disabled={tokenapp || tagapp}
                      >
                        ADD LIQUIDITY
                        <Loader />
                      </button>
                    </div>
                  </div>
                )}
                {(to == "BNB" || from == "BNB") && (
                  <div
                    className="field-container trload"
                    style={{
                      marginTop: "3%",
                      gridColumn: "1/3",
                      borderRadius: "5px",
                    }}
                  >
                    <button
                      className={
                        loadinggadd === true ? "liqbutton" : "liqbutton disnone"
                      }
                      onClick={addliqToken}
                    >
                      ADD LIQUIDITY
                      <Loader />
                    </button>
                  </div>
                )}
              </div>
              {to == "BNB" || from == "BNB" ? (
                <ul
                  className="accinfo-list pb-2"
                  style={{ marginTop: "25px", marginBottom: "20px" }}
                >
                  <li>
                    <label>
                      {from}/{to} LPT{" "}
                    </label>
                    <span>
                      {noround(
                        parseFloat(web3.utils.fromWei(pairbalance), "ether"),
                        4
                      )}{" "}
                      LPT
                    </span>
                  </li>
                  <li>
                    <label>TAG</label>
                    <span>
                      {noround(
                        parseFloat(web3.utils.fromWei(tagbalance), "ether"),
                        4
                      )}{" "}
                      TAG / $
                      {numFormatter(
                        noround(
                          parseFloat(web3.utils.fromWei(tagbalance), "ether") *
                            (state.loading
                              ? "0"
                              : parseFloat(state.data[0].TAG_USD).toFixed(4)),
                          4
                        )
                      )}
                    </span>
                  </li>
                </ul>
              ) : (
                <ul
                  className="accinfo-list pb-2"
                  style={{ marginTop: "8px", marginBottom: "6px" }}
                >
                  <li>
                    <label>
                      {from}/{to} LPT{" "}
                    </label>
                    <span>
                      {noround(
                        parseFloat(web3.utils.fromWei(pairbalance), "ether"),
                        4
                      )}{" "}
                      LPT
                    </span>
                  </li>
                  <li>
                    <label>TAG</label>
                    <span>
                      {noround(
                        parseFloat(web3.utils.fromWei(tagbalance), "ether"),
                        4
                      )}{" "}
                      TAG / $
                      {noround(
                        parseFloat(web3.utils.fromWei(tagbalance), "ether") *
                          (state.loading
                            ? "0"
                            : parseFloat(state.data[0].TAG_USD).toFixed(4)),
                        4
                      )}
                    </span>
                  </li>
                </ul>
              )}
              <div className="form-container">
                <div
                  className="field-container"
                  style={{ background: "rgb(222 240 255)", padding: "12px" }}
                >
                  <label
                    className="labl"
                    htmlFor="name"
                    style={{ padding: "5px" }}
                  >
                    Input
                  </label>
                  <label
                    className="labl"
                    htmlFor="bal"
                    style={{ padding: "5px", float: "right" }}
                  >
                    Balance :{" "}
                    {noround(
                      parseFloat(web3.utils.fromWei(pairbalance), "ether"),
                      4
                    )}
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      id="bnb"
                      className="inp"
                      maxLength="10"
                      type="text"
                      name="fromvalue"
                      style={{
                        background: "transparent",
                        border: "0px",
                        outline: "none",
                      }}
                      placeholder="0.0"
                      onChange={(e) => handlepairChange(e.target.value)}
                      value={pairval}
                    />
                    <button
                      style={{
                        display: "inline-flex",
                        background: "transparent",
                        border: "0px",
                        height: "16px",
                        marginTop: "7px",
                        fontWeight: "700",
                        color: "#1e82ce",
                        cursor: "pointer",
                        outline: "0px",
                      }}
                      onClick={(e) =>
                        setPairval(
                          noround(
                            parseFloat(
                              web3.utils.fromWei(pairbalance),
                              "ether"
                            ),
                            8
                          )
                        )
                      }
                    >
                      MAX
                    </button>
                    <button
                      style={{
                        display: "inline-flex",
                        background: "transparent",
                        border: "0px",
                        height: "16px",
                        marginTop: "7px",
                        outline: "0px",
                      }}
                    >
                      LPT
                    </button>
                  </div>
                </div>
                <div
                  className="form-container"
                  style={{
                    gridArea: "security",
                    marginTop: "3%",
                    padding: "0px",
                  }}
                >
                  <div
                    className="field-container trloadsm"
                    style={{
                      gridColumn: "1",
                      marginRight: "1%",
                      borderRadius: "5px",
                    }}
                  >
                    <button
                      className={
                        loadinggaplpt === true
                          ? "liqbutton"
                          : "liqbutton disnone"
                      }
                      onClick={approveLpToken}
                      disabled={!lptapp}
                    >
                      APPROVE REMOVAL
                      <Loader />
                    </button>
                  </div>
                  <div
                    className="field-container trloadsm"
                    style={{
                      gridColumn: "2/3",
                      gridRow: "1",
                      marginLeft: "1%",
                      borderRadius: "5px",
                    }}
                  >
                    <button
                      className={
                        loadinggrem === true ? "liqbutton" : "liqbutton disnone"
                      }
                      onClick={remliqToken}
                      disabled={lptapp}
                    >
                      REMOVE LIQUIDITY
                      <Loader />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----second column---- */}
        <div className="flxcolumn">
          <div className="app-content trcontent liqheight">
            <LiquidityData />
          </div>
        </div>
      </div>

      <Datarow />
      <Burnrow />
      <StatRow />

      <br />

      <Footer />
    </>
  );
}
export default LiquidityPan;
