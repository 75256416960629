import React, { useContext, useState } from "react";
import { CommonDataContext } from "./CommonDataContext";
import Loader from "../components/Loader";
import ls from "local-storage";

function LiquidityData() {
  const valContext = useContext(CommonDataContext);
  const {
    state,
    farmstate,
    setFarmstate,
    marketval,
    tradestate,
    setTradestate,
    lpbnbval,
    lpbusdval,
    lptmoonval,
    lpstagval,
    tagusd,
    bnblpt,
    busdtpl,
    tmoonlpt,
    staglpt,
  } = valContext;

  const [trtype, setTrtype] = useState(0);

  function handleFtype(x) {
    if (x == 0) {
      setTrtype(0);
      setFarmstate({ loading: true });
      const url = "https://tagdev.info/v1/summary/getFarm/20";
      console.log("fetching");

      //fetch trade data
      fetch(url)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setFarmstate({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });
      ls.set("ftype", 0);
    } else {
      setTrtype(1);
      setFarmstate({ loading: true });
      const url =
        "https://tagdev.info/v1/summary/getFarm/20/" + ls.get("usradd");
      console.log("fetching");

      //fetch trade data
      fetch(url)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setFarmstate({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });
      ls.set("ftype", 1);
    }
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num.toFixed(2); // if value < 1000, nothing to do
    }
  }

  return (
    <div className="tagtrade-content">
      <h5
        className="headingin"
        style={{ float: "none", textAlign: "center", marginBottom: "20px" }}
      >
        <span style={{ fontWeight: "bold" }}>Total Staked :</span>{" "}
        {farmstate.loading
          ? "0"
          : numFormatter(
              lpbnbval * bnblpt +
                lpbusdval * busdtpl +
                lptmoonval * tmoonlpt +
                lpstagval * staglpt
            )}{" "}
        USD | <br className="splitbr" /> <br className="splitbr" />
        <span style={{ fontWeight: "bold" }}>24 Hours Farmed :</span>{" "}
        {farmstate.loading
          ? "0"
          : numFormatter(1200 * 24 * (2 * 0.10425) * tagusd)}{" "}
        USD /{" "}
        {farmstate.loading ? "0" : numFormatter(1200 * 24 * (2 * 0.10425))} TAG
      </h5>
      <ul className="nav nav-tabs">
        <li className="nav-item" style={{ cursor: "pointer" }}>
          <a
            className={trtype == 0 ? "nav-link active" : "nav-link"}
            aria-current="page"
            onClick={() => handleFtype(0)}
          >
            Latest Add/Remove
          </a>
        </li>
        <li
          className="nav-item"
          style={{ cursor: "pointer", margin: "0 0 0 5px" }}
        >
          <a
            className={trtype == 1 ? "nav-link active" : "nav-link"}
            onClick={() => handleFtype(1)}
          >
            Your Transactions
          </a>
        </li>
      </ul>
      <div className="body tdbdy" style={{ overflow: "auto" }}>
        <table id="squadTable" class="table">
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>Type</th>
              <th>Address</th>
              <th>Pool</th>
              <th>Amount</th>
              <th>Tx Hash</th>
            </tr>
          </thead>
          <tbody className="trbody">
            {farmstate.loading ? (
              <Loader />
            ) : (
              Object.keys(farmstate.data).map((k, i) => {
                let data = farmstate.data;
                return (
                  <tr
                    key={i}
                    className={data[i].type == "rem" ? "selltr" : "buytr"}
                    style={{ textAlign: "center" }}
                  >
                    <td style={{ textTransform: "capitalize" }}>
                      {data[i].type == "rem" ? "REMOVE" : "ADD"}
                    </td>
                    <td>{data[i].address.slice(0, 8)}...</td>
                    <td>{data[i].pool}</td>
                    <td>{parseFloat(data[i].amount).toFixed(3)} LPT</td>
                    <td>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={"https://bscscan.com/tx/" + data[i].txn}
                      >
                        {data[i].txn.slice(0, 8)}
                      </a>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LiquidityData;
