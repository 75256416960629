import React, { useEffect, useRef } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";

function HtagChart() {
  const fulldata = useRef(new Array());
  const hchartContainerRef = useRef();
  const hchart = useRef();
  const hlineSeries = useRef();
  const hvolumeSeries = useRef();
  const resizeObserver = useRef();
  const url = "https://ledger-trans.tagdev.info/v1/summary/getStaking/30";

  useEffect(() => {
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        fulldata.current = data;

        const priceData = [];
        const volumeData = [];

        for (var i = 0; i < fulldata.current.length; i++) {
          priceData.push({
            time: Date.parse(fulldata.current[i].time) / 1000,
            value: parseFloat(fulldata.current[i].avg).toFixed(5),
          });
          volumeData.push({
            time: Date.parse(fulldata.current[i].time) / 1000,
            value: parseFloat(fulldata.current[i]._id).toFixed(2),
          });
        }

        hchart.current = createChart(hchartContainerRef.current, {
          crosshair: {
            mode: CrosshairMode.Normal,
          },
          grid: {
            vertLines: {
              color: "#ddd",
              style: 1,
              visible: true,
            },
            horzLines: {
              color: "#ddd",
              style: 1,
              visible: true,
            },
          },
          priceScale: {
            borderColor: "#ababab",
            scaleMargins: {
              top: 0,
              bottom: 0.2,
            },
            entireTextOnly: true,
          },
          timeScale: {
            barSpacing: 18,
            fixLeftEdge: true,
            borderColor: "#ababab",
          },
        });

        hlineSeries.current = hchart.current.addLineSeries({
          scaleMargins: {
            top: 0.5,
            bottom: 0,
          },
          autoscaleInfoProvider: () => ({
            priceRange: {
              minValue: 0,
              maxValue: 0.28,
            },
          }),
          priceFormat: {
            type: "price",
            precision: 5,
            minMove: 0.00001,
          },
        });

        hlineSeries.current.setData(priceData);

        const priceLine1 = hlineSeries.current.createPriceLine({
          price: parseFloat(fulldata.current[0].avg).toFixed(5),
          color: "green",
          lineWidth: 2,
          axisLabelVisible: true,
          title: "Est. Yield Per HTAG",
        });

        hvolumeSeries.current = hchart.current.addHistogramSeries({
          color: "#26a69a",
          lineWidth: 18,
          title: "HTAG VOLUME",
          overlay: true,
          scaleMargins: {
            top: 0.9,
            bottom: 0,
          },
          autoscaleInfoProvider: () => ({
            priceRange: {
              minValue: 0,
              maxValue: 20000,
            },
          }),
        });

        hvolumeSeries.current.setData(volumeData);

        const priceLine2 = hvolumeSeries.current.createPriceLine({
          price: parseFloat(fulldata.current[0]._id).toFixed(5),
          color: "green",
          lineWidth: 2,
          axisLabelVisible: true,
          title: "HTAGS  STAKED",
        });

        hchart.current.timeScale().fitContent();
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  }, []);

  // Resize hchart on container resizes.
  useEffect(() => {
    if (hchart.current != undefined) {
      resizeObserver.current = new ResizeObserver((entries) => {
        const { width, height } = entries[0].contentRect;
        hchart.current.applyOptions({ width, height });
        setTimeout(() => {
          hchart.current.timeScale().fitContent();
        }, 0);
      });

      resizeObserver.current.observe(hchartContainerRef.current);

      return () => resizeObserver.current.disconnect();
    }
  }, []);

  return (
    <div
      ref={hchartContainerRef}
      className="chart-container"
      style={{ height: "320px" }}
    />
  );
}

export default HtagChart;
