import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BscConnector,
  UserRejectedRequestError,
} from '@binance-chain/bsc-connector';
import {
  ConnectionRejectedError,
  UseWalletProvider,
} from 'use-wallet'
export const bsc = new BscConnector({
  supportedChainIds: [56, 97] // later on 1 ethereum mainnet and 3 ethereum ropsten will be supported
})

//To disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

ReactDOM.render(
  <React.StrictMode>
    <UseWalletProvider chainId={56}  connectors={{
      // This is how connectors get configured
   
      walletconnect: { rpcUrl: "https://bsc-dataseed.binance.org/" },
      bsc: bsc,
      
     
    }}>
    <App />
    </UseWalletProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
