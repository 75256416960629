import React, { useState, useEffect, useContext } from "react";
import { useWallet, UseWalletProvider } from "use-wallet";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import FarmData from "../components/FarmData";
import Datarow from "../components/Datarow";
import Burnrow from "../components/Burnrow";
import StatRow from "../components/StatRow";
import Dropdownlpt from "../components/Dropdownlpt";
import Footer from "../components/Footer";
import { CommonDataContext } from "./CommonDataContext";
import { store } from "react-notifications-component";
import ls, { set } from "local-storage";
import abiVal from "../data/abi.json";
import LoginCheck from "../components/LoginCheck";

const Web3 = require("web3");
const web3 = new Web3("https://bsc-dataseed.binance.org/");

const FACTORY_ADDRESS = abiVal.factorycontract;
const FACTORY_ABI = abiVal.factoryabi;

const OLDFARM_ADDRESS = abiVal.oldfarmcontract;
const OLDFARM_ABI = abiVal.oldfarmabi;

const BNBFARM_ADDRESS = abiVal.bnbfarmcontract;
const BUSDFARM_ADDRESS = abiVal.busdfarmcontract;
const TMOONFARM_ADDRESS = abiVal.tmoonfarmcontract;
const STAGFARM_ADDRESS = abiVal.stagfarmcontract;
const EGOLDBUSDFARM_ADDRESS = abiVal.egoldbusdfarmcontract;

const FARM_ABIv2 = abiVal.farmabiv2;

const TAG_ADDRESS = abiVal.tagcontract;
const TAG_ABI = abiVal.tagabi;

const PAIR_ABI = abiVal.pairabi;

localStorage.setItem("flag", "false");
localStorage.setItem("ftype", "0");

function LPFarm() {
  const valContext = useContext(CommonDataContext);
  const {
    runCommand,
    state,
    setState,
    setFarmstate,
    marketstate,
    setMarketstate,
    setMarketval,
    setTagholders,
    setHtagreg,
    setHtaghold,
    setStakecount,
    from,
    to,
    lpbnbval,
    setLpbnbval,
    lpbusdval,
    setLpbusdval,
    lptmoonval,
    setLptmoonval,
    lpstagval,
    setLpstagval,
    setUnmintedtoken,
    tagusd,
    setTagusd,
    setBNBlpt,
    setBUSDlpt,
    setTMOONlpt,
    setSTAGlpt,
  } = valContext;

  const wallet = useWallet();
  let history = useHistory();
  const webb3 = new Web3(wallet.ethereum);

  const factoryInstancew3 = new web3.eth.Contract(FACTORY_ABI, FACTORY_ADDRESS);

  const [tagbalance, setTagbal] = useState("");
  const [pairbalance, setPairbal] = useState("");
  const [deposit, setDeposit] = useState(0);
  const [yieldpb, setYeildpb] = useState(0);
  const [totallp, setTotallp] = useState(0);
  const [tagearn, setTagearn] = useState(0);
  const [pairval, setPairval] = useState("");
  const [loadinggadd, setLoadinggadd] = useState(false);
  const [loadinggaptok, setLoadinggaptok] = useState(false);
  const [loadinggrem, setLoadinggrem] = useState(false);
  const [loadinggrem2, setLoadinggrem2] = useState(false);
  const [loadinggrem3, setLoadinggrem3] = useState(false);
  const [tokenapp, setTokenapp] = useState(true); //token approve button enabled
  const [lpegoldbusdval, setLpegoldbusdval] = useState(0);
  const [egoldusd, setEgoldusd] = useState(0);
  const [egtradeData, setegTradeData] = useState({ data: null, loading: true });

  const [tmoonv4, setTmoonv4] = useState("0");
  const [tmoonv5, setTmoonv5] = useState("0");
  const [stagv1, setStagv1] = useState("0");
  const [stagv2, setStagv2] = useState("0");
  const [bnbv1, setBnbv1] = useState("0");
  const [bnbv2, setBnbv2] = useState("0");
  const [busdv1, setBusdv1] = useState("0");
  const [busdv2, setBusdv2] = useState("0");

  //onload
  useEffect(() => {
    getTokbal();
  }, []);

  //on from or to change from dropdown
  useEffect(() => {
    getTokbal();
  }, [from, to]);

  //on wallet connect or disconnect
  useEffect(() => {
    if (runCommand == 0) {
      setPairbal("0");
      setTagbal("0");
      setDeposit("0");
      setTagearn("0");
    } else getTokbal();
  }, [runCommand]);

  const fetchTotalLPT = async () => {
    const w3 = new Web3("https://bsc-dataseed.binance.org/");
    //get total lp in farm
    const tagadd = TAG_ADDRESS;
    var tokencaddressbnb = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
    var tokencaddressbusd = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
    var tokencaddresstmoon = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
    var tokencaddressstag = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";

    var tokencaddress2 = tagadd;

    let PAIR_ADDRESSbnb;
    await factoryInstancew3.methods
      .getPair(tokencaddressbnb, tokencaddress2)
      .call(function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        PAIR_ADDRESSbnb = res;
      });

    const pairInstanceto = new w3.eth.Contract(PAIR_ABI, PAIR_ADDRESSbnb);
    await pairInstanceto.methods
      .balanceOf(BNBFARM_ADDRESS)
      .call({}, async function (error, res) {
        if (error != null) {
          console.log(error);
          return;
        } else {
          setBNBlpt(web3.utils.fromWei(res), "ether");
        }
      });

    let PAIR_ADDRESSbusd;
    await factoryInstancew3.methods
      .getPair(tokencaddressbusd, tokencaddress2)
      .call(function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        PAIR_ADDRESSbusd = res;
      });

    const pairInstancetoo = new w3.eth.Contract(PAIR_ABI, PAIR_ADDRESSbusd);
    await pairInstancetoo.methods
      .balanceOf(BUSDFARM_ADDRESS)
      .call({}, async function (error, res) {
        if (error != null) {
          console.log(error);
          return;
        } else {
          setBUSDlpt(web3.utils.fromWei(res), "ether");
        }
      });

    let PAIR_ADDRESStmoon;
    await factoryInstancew3.methods
      .getPair(tokencaddresstmoon, tokencaddress2)
      .call(function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        PAIR_ADDRESStmoon = res;
      });

    const pairInstancetooo = new w3.eth.Contract(PAIR_ABI, PAIR_ADDRESStmoon);
    await pairInstancetooo.methods
      .balanceOf(TMOONFARM_ADDRESS)
      .call({}, async function (error, res) {
        if (error != null) {
          console.log(error);
          return;
        } else {
          setTMOONlpt(web3.utils.fromWei(res), "ether");
        }
      });

    let PAIR_ADDRESSstag;
    await factoryInstancew3.methods
      .getPair(tokencaddressstag, tokencaddress2)
      .call(function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        PAIR_ADDRESSstag = res;
      });

    const pairInstancetoooo = new w3.eth.Contract(PAIR_ABI, PAIR_ADDRESSstag);
    await pairInstancetoooo.methods
      .balanceOf(STAGFARM_ADDRESS)
      .call({}, async function (error, res) {
        if (error != null) {
          console.log(error);
          return;
        } else {
          setSTAGlpt(web3.utils.fromWei(res), "ether");
        }
      });
  };

  //initial load for trade and market data
  useEffect(() => {
    setState((state) => ({ data: state.data, error: false, loading: true }));
    const url = "https://tagdev.info/v1/summary/getLatestTrades/20";
    const farmurl = "https://tagdev.info/v1/summary/getFarm/20";
    const farmurlper =
      "https://tagdev.info/v1/summary/getFarm/20/" + ls.get("usradd");
    const url2 = "https://tagdev.info/v1/summary/get24MarketStat";
    const regurl = "https://tagcoin-api.tagdev.info/v1/summary/";
    const marketurl = "https://tagdev.info/v1/summary/";
    const egmarketurl = "https://egold-marketdata.herokuapp.com/v1/summary/";
    const latestblockurl =
      "https://ledger-trans.tagdev.info/v1/summary/getLastIndexed/last";
    const unmintedurl = "https://ledger.tagdev.info/v1/summary/getUnminted";
    console.log("fetching");

    const egurltrade =
      "https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/20";
    fetch(egurltrade)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) => setegTradeData({ data: newData, loading: false }))
      .catch(function (error) {
        console.log(error);
      });

    //fetch trade data
    fetch(url)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) =>
        setState({ data: newData, error: false, loading: false })
      )
      .catch(function (error) {
        console.log(error);
      });

    //fetch liquidity data
    fetch(ls.get("ftype") == 0 ? farmurl : farmurlper)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) =>
        setFarmstate({ data: newData, error: false, loading: false })
      )
      .catch(function (error) {
        console.log(error);
      });

    //fetch 24 hour market data
    fetch(url2)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) =>
        setMarketstate({ data: newData, error: false, loading: false })
      )
      .catch(function (error) {
        console.log(error);
      });

    //fetch registered count data
    fetch(regurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setHtagreg(data.hashtags);
        setHtaghold(data.wallets);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //fetch supply and liquidity data
    fetch(marketurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setMarketval({
          supply: data.supply,
          totaltag: data.pools.totalTag,
          totalusd: data.pools.totalUsd,
          lockedsupply: data.lockedSupply,
        });
        setTagholders(data.holders);
        setLpbnbval(data.pools.bnblptusdPC);
        setLpbusdval(data.pools.busdlptusdPC);
        setLptmoonval(data.pools.tmoonlptusdPC);
        setLpstagval(data.pools.staglptusdPC);
        setTagusd(data.pools.tagusd);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //fetch egold liquidity data
    fetch(egmarketurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setLpegoldbusdval(data.pools.busdlptusdPC);
        setEgoldusd(data.pools.tagusd);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    fetchTotalLPT();

    //fetch staking count data
    fetch(latestblockurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return fetch(
          "https://ledger-trans.tagdev.info/v1/summary/getSummary/" + data.id
        );
      })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setStakecount(data.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //fetch unminted tokens

    fetch(unmintedurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setUnmintedtoken(data.unminted);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //load with interval
    const interval = setInterval(() => {
      const url = "https://tagdev.info/v1/summary/getLatestTrades/20";
      const url2 = "https://tagdev.info/v1/summary/get24MarketStat";

      console.log("fetching");
      fetch(url)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setState({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });

      //fetch liquidity data
      fetch(ls.get("ftype") == 0 ? farmurl : farmurlper)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setFarmstate({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });

      fetch(url2)
        .then((data) => data.json())
        .then((obj) =>
          Object.keys(obj).map((key) => {
            let newData = obj[key];
            newData.key = key;
            return newData;
          })
        )
        .then((newData) =>
          setMarketstate({ data: newData, error: false, loading: false })
        )
        .catch(function (error) {
          console.log(error);
        });
    }, 15000);

    const interval2 = setInterval(() => {
      //fetch registered count data
      fetch(regurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setHtagreg(data.hashtags);
          setHtaghold(data.wallets);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });

      //fetch supply and liquidity data
      fetch(marketurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setMarketval({
            supply: data.supply,
            totaltag: data.pools.totalTag,
            totalusd: data.pools.totalUsd,
            lockedsupply: data.lockedSupply,
          });
          setTagholders(data.holders);
          setLpbnbval(data.pools.bnblptusdPC);
          setLpbusdval(data.pools.busdlptusdPC);
          setLptmoonval(data.pools.tmoonlptusdPC);
          setLpstagval(data.pools.staglptusdPC);
          setTagusd(data.pools.tagusd);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });

      fetch(egmarketurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setLpegoldbusdval(data.pools.busdlptusdPC);
          setEgoldusd(data.pools.tagusd);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });

      fetchTotalLPT();

      //fetch staking count data
      fetch(latestblockurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          return fetch(
            "https://ledger-trans.tagdev.info/v1/summary/getSummary/" + data.id
          );
        })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setStakecount(data.count);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });

      //fetch unminted tokens

      fetch(unmintedurl)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setUnmintedtoken(data.unminted);
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });
    }, 60000);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, []);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  //sets value for token balance and lp token balance
  const getTokbal = async () => {
    const w3 = new Web3("https://bsc-dataseed.binance.org/");
    const tagcontractInstanceto = new w3.eth.Contract(TAG_ABI, TAG_ADDRESS);

    if (from == "BNB") {
      var farmInstance = new web3.eth.Contract(FARM_ABIv2, BNBFARM_ADDRESS);
      var farmaddr = BNBFARM_ADDRESS;
    } else if (from == "BUSD") {
      var farmInstance = new web3.eth.Contract(FARM_ABIv2, BUSDFARM_ADDRESS);
      var farmaddr = BUSDFARM_ADDRESS;
    } else if (from == "TMOON") {
      var farmInstance = new web3.eth.Contract(FARM_ABIv2, TMOONFARM_ADDRESS);
      var farmaddr = TMOONFARM_ADDRESS;
    } else if (from == "STAG") {
      var farmInstance = new web3.eth.Contract(FARM_ABIv2, STAGFARM_ADDRESS);
      var farmaddr = STAGFARM_ADDRESS;
    } else if (from == "EGOLD") {
      var farmInstance = new web3.eth.Contract(
        FARM_ABIv2,
        EGOLDBUSDFARM_ADDRESS
      );
      var farmaddr = EGOLDBUSDFARM_ADDRESS;
    }

    if (from == "BNB") {
      var pid = 0;
    } else if (from == "BUSD") {
      var pid = 0;
    } else if (from == "TMOON") {
      var pid = 0;
    } else if (from == "STAG") {
      var pid = 0;
    } else if (from == "EGOLD") {
      var pid = 0;
    }

    //set lp-token balance
    if (from == null || to == null) {
      setPairbal("0");
    } else {
      const tagadd = TAG_ADDRESS;
      var tokencaddress2 = tagadd;
      if (from == "BNB") {
        var tokencaddress1 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
      } else if (from == "BUSD") {
        var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (from == "TMOON") {
        var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (from == "STAG") {
        var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (from == "EGOLD") {
        var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
        var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      }

      let PAIR_ADDRESS;
      await factoryInstancew3.methods
        .getPair(tokencaddress1, tokencaddress2)
        .call(function (err, res) {
          if (err) {
            console.log("An error occured", err);
            return;
          }
          PAIR_ADDRESS = res;
        });

      const pairInstanceto = new w3.eth.Contract(PAIR_ABI, PAIR_ADDRESS);
      if (ls.get("usradd")) {
        if (localStorage.getItem("flag") == "true") {
          await timeout(12000);
          localStorage.setItem("flag", "false");
        }
        await pairInstanceto.methods
          .balanceOf(ls.get("usradd"))
          .call({}, function (error, res) {
            if (error != null) {
              console.log(error);
              return;
            } else {
              console.log("tokbal - ", res);
              setPairbal(res);
            }
          });
      }
    }

    //get total lp in farm
    const tagadd = TAG_ADDRESS;
    var tokencaddress2 = tagadd;
    if (from == "BNB") {
      var tokencaddress1 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
    } else if (from == "BUSD") {
      var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
    } else if (from == "TMOON") {
      var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
    } else if (from == "STAG") {
      var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
    } else if (from == "EGOLD") {
      var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
      var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
    }

    let PAIR_ADDRESS;
    await factoryInstancew3.methods
      .getPair(tokencaddress1, tokencaddress2)
      .call(function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        PAIR_ADDRESS = res;
      });

    const pairInstanceto = new w3.eth.Contract(PAIR_ABI, PAIR_ADDRESS);
    await pairInstanceto.methods
      .balanceOf(farmaddr)
      .call({}, async function (error, res) {
        if (error != null) {
          console.log(error);
          return;
        } else {
          setTotallp(res);
        }
      });

    //get reward per block
    await farmInstance.methods.rewardPerBlock().call(function (err, res) {
      if (err) {
        console.log("An error occured", err);
        return;
      } else {
        setYeildpb(res);
      }
    });

    if (ls.get("usradd")) {
      //get Farm Deposit balance
      await farmInstance.methods
        .deposited(pid, ls.get("usradd"))
        .call(function (err, res) {
          if (err) {
            console.log("An error occured", err);
            return;
          }
          setDeposit(res);
        });

      //get TAG Earned value
      try {
        await farmInstance.methods
          .pending(pid, ls.get("usradd"))
          .call(function (err, res) {
            console.log("tagearnnnn--", tagearn);
            if (err) {
              console.log("An error occured", err);
              res = 0;
            }
            setTagearn(res);
          });
      } catch (e) {
        setTagearn(0);
        console.log(e);
      }

      //get Tag balance
      await tagcontractInstanceto.methods
        .balanceOf(ls.get("usradd"))
        .call({}, function (error, res) {
          if (error != null) {
            console.log(error);
            res = 0;
          } else {
            console.log("tagbal - ", res);
            setTagbal(res);
          }
        });

      if (from == "TMOON") {
        //Tmoon v4
        var farmInstancet4 = new web3.eth.Contract(
          FARM_ABIv2,
          "0xD79ADBCAD741f5B6EFD2f9BDa39746D3cAC03edC"
        );

        //Tmoon v5
        var farmInstancet5 = new web3.eth.Contract(
          FARM_ABIv2,
          "0x822709Bf9CEFecE7A0447c0Fac02460776818af4"
        );

        //get TMOON v4 Farm Deposit balance
        await farmInstancet4.methods
          .deposited(pid, ls.get("usradd"))
          .call(function (err, res) {
            if (err) {
              console.log("An error occured while getting tmoon v2", err);
              res = 0;
            }
            setTmoonv4(res);
          });

        //get TMOON v5 Farm Deposit balance
        await farmInstancet5.methods
          .deposited(pid, ls.get("usradd"))
          .call(function (err, res) {
            if (err) {
              console.log("An error occured while getting tmoon v1", err);
              res = 0;
            }
            setTmoonv5(res);
          });
      }

      if (from == "STAG") {
        var farmInstanceS = new web3.eth.Contract(
          FARM_ABIv2,
          "0xF063540C79e0b66f53b7fc32581aa22179e9A83e"
        );
        //get STAG v1 Farm Deposit balance
        await farmInstanceS.methods
          .deposited(pid, ls.get("usradd"))
          .call(function (err, res) {
            if (err) {
              console.log("An error occured", err);
              res = 0;
            }
            setStagv1(res);
          });

        var farmInstanceX = new web3.eth.Contract(
          FARM_ABIv2,
          "0x7D7e6Aa31b8E011ddEBF16f61b84f44EE1bDD6f3"
        );
        //get STAG v1 Farm Deposit balance
        await farmInstanceX.methods
          .deposited(pid, ls.get("usradd"))
          .call(function (err, res) {
            if (err) {
              console.log("Cant fetch stag v2 balance", err);
              res = 0;
            }
            setStagv2(res);
          });
      }

      if (from == "BNB") {
        var farmInstanceS = new web3.eth.Contract(
          FARM_ABIv2,
          "0x477C8FDe42D8C9420EC0B5112cC0d9fd13bD4008"
        );
        //get STAG v1 Farm Deposit balance
        await farmInstanceS.methods
          .deposited(pid, ls.get("usradd"))
          .call(function (err, res) {
            if (err) {
              console.log("An error occured", err);
              res = 0;
            }
            setBnbv1(res);
          });
      }

      if (from == "BUSD") {
        var farmInstanceS = new web3.eth.Contract(
          FARM_ABIv2,
          "0xe2cbFcD40006903B55b61a72D0015CDC852c8a4a"
        );
        //get STAG v1 Farm Deposit balance
        await farmInstanceS.methods
          .deposited(0, ls.get("usradd"))
          .call(function (err, res) {
            if (err) {
              console.log("An error occured", err);
              res = 0;
            }
            setBusdv1(res);
          });
      }
    }
  };

  //for setting lp token balance
  async function handlepairChange(val) {
    setPairval(val);
  }

  //To approve LP Token
  async function approveToken() {
    if (pairval != "") {
      setLoadinggaptok(true);
      let gaslim = await webb3.eth.estimateGas({ from: wallet.account });
      let gaspri = await webb3.eth.getGasPrice();
      const tagadd = TAG_ADDRESS;
      var tokencaddress2 = tagadd;

      if (from == "BNB") {
        var fromconaddr = BNBFARM_ADDRESS;
      }
      if (from == "BUSD") {
        var fromconaddr = BUSDFARM_ADDRESS;
      } else if (from == "TMOON") {
        var fromconaddr = TMOONFARM_ADDRESS;
      } else if (from == "STAG") {
        var fromconaddr = STAGFARM_ADDRESS;
      } else if (from == "EGOLD") {
        var fromconaddr = EGOLDBUSDFARM_ADDRESS;
      }

      if (from == "BNB") {
        var tokencaddress1 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
      } else if (from == "BUSD") {
        var tokencaddress1 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      } else if (from == "TMOON") {
        var tokencaddress1 = "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f";
      } else if (from == "STAG") {
        var tokencaddress1 = "0xf21e21333a9424e67b2fefd98152c7b478ea985a";
      } else if (from == "EGOLD") {
        var tokencaddress1 = "0x8005D97E993668a528008d16338B42f9e976ED0F";
        var tokencaddress2 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
      }

      let PAIR_ADDRESS;
      await factoryInstancew3.methods
        .getPair(tokencaddress1, tokencaddress2)
        .call(function (err, res) {
          if (err) {
            console.log("An error occured", err);
            return;
          }
          PAIR_ADDRESS = res;
        });
      const pairInstanceto = new webb3.eth.Contract(PAIR_ABI, PAIR_ADDRESS);

      var appval = pairval;

      await pairInstanceto.methods
        .approve(fromconaddr, webb3.utils.toWei(appval))
        .send({
          from: wallet.account,
          gasLimit: gaslim,
          gasPrice: gaspri,
        })
        .on("receipt", async function (hash) {
          console.log("Approved");
          setTokenapp(false);
          setLoadinggaptok(false);

          store.addNotification({
            id: "approvee-complete",
            title: "Approved",
            message: "Transaction was approved",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, receipt) {
          console.log("error");
          setLoadinggadd(false);
          store.addNotification({
            id: "approvee-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    }
  }

  //to add liquidity
  async function depositFarm() {
    setLoadinggadd(true);
    const tagadd = TAG_ADDRESS;

    if (from == "BNB") {
      var farmInstance = new webb3.eth.Contract(FARM_ABIv2, BNBFARM_ADDRESS);
    }
    if (from == "BUSD") {
      var farmInstance = new webb3.eth.Contract(FARM_ABIv2, BUSDFARM_ADDRESS);
    } else if (from == "TMOON") {
      var farmInstance = new webb3.eth.Contract(FARM_ABIv2, TMOONFARM_ADDRESS);
    } else if (from == "STAG") {
      var farmInstance = new webb3.eth.Contract(FARM_ABIv2, STAGFARM_ADDRESS);
    } else if (from == "EGOLD") {
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        EGOLDBUSDFARM_ADDRESS
      );
    }

    if (from == "BNB") {
      var pid = 0;
    } else if (from == "BUSD") {
      var pid = 0;
    } else if (from == "TMOON") {
      var pid = 0;
    } else if (from == "STAG") {
      var pid = 0;
    } else if (from == "EGOLD") {
      var pid = 0;
    }

    var appval = pairval;

    await farmInstance.methods
      .deposit(pid, webb3.utils.toWei(appval))
      .send({
        from: wallet.account,
      })
      .on("receipt", async function (hashh) {
        console.log("Liquidity added");
        localStorage.setItem("flag", "true");
        await getTokbal();
        setLoadinggadd(false);
        setTokenapp(true);

        store.addNotification({
          id: "addliq-complete",
          title: "SUCCESS",
          message: "Farm deposit complete",
          type: "success",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
      })
      .on("error", function (error, hashh) {
        console.log("error");
        setLoadinggadd(false);
        store.addNotification({
          id: "addliq-error",
          title: "Error",
          message: error.message,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 8000,
          },
        });
      });
  }

  //To remove liquidity
  async function removeFarm(old = 0) {
    if (from == "BNB")
      var farmInstance = new webb3.eth.Contract(FARM_ABIv2, BNBFARM_ADDRESS);
    else if (from == "BUSD")
      var farmInstance = new webb3.eth.Contract(FARM_ABIv2, BUSDFARM_ADDRESS);
    else if (from == "TMOON")
      var farmInstance = new webb3.eth.Contract(FARM_ABIv2, TMOONFARM_ADDRESS);
    else if (from == "STAG")
      var farmInstance = new webb3.eth.Contract(FARM_ABIv2, STAGFARM_ADDRESS);
    else if (from == "EGOLD")
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        EGOLDBUSDFARM_ADDRESS
      );

    if (old == 1) {
      setLoadinggrem2(true);
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        "0xD79ADBCAD741f5B6EFD2f9BDa39746D3cAC03edC"
      ); //v4 tmoon
    } else if (old == 2) {
      setLoadinggrem3(true);
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        "0x68dC4d095b22A20e2daB8c50Ff3eFcB075afc1DC"
      );
    } else if (old == 3) {
      setLoadinggrem2(true);
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        "0xF063540C79e0b66f53b7fc32581aa22179e9A83e"
      );
    } else if (old == 4) {
      setLoadinggrem3(true);
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        "0x7D7e6Aa31b8E011ddEBF16f61b84f44EE1bDD6f3"
      );
    } else if (old == 5) {
      setLoadinggrem3(true);
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        "0x822709Bf9CEFecE7A0447c0Fac02460776818af4"
      );
    } else if (old == 6) {
      setLoadinggrem2(true);
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        "0xB609853B9C81D199338605a19ba1bC7e630f289E"
      );
    } else if (old == 7) {
      setLoadinggrem2(true);
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        "0xe5b9240720bA9E8379effE9BF530CeeAdf198Ada"
      );
    } else if (old == 8) {
      setLoadinggrem2(true);
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        "0x3241a954c3E837489b9eB45d431aDd87F8000D87"
      );
    } else if (old == 9) {
      setLoadinggrem3(true);
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        "0x64F052D164a7C1bd9385C0199Ac6B48e28B40EA4"
      ); //v4 busd
    } else if (old == 10) {
      setLoadinggrem3(true);
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        "0x87341037Cb40AF88cb7d8C15ac782ffaE74D517c"
      ); //v3 bnb
    } else if (old == 11) {
      setLoadinggrem3(true);
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        "0x477C8FDe42D8C9420EC0B5112cC0d9fd13bD4008"
      ); //v5 bnb
    } else if (old == 12) {
      setLoadinggrem3(true);
      var farmInstance = new webb3.eth.Contract(
        FARM_ABIv2,
        "0xe2cbFcD40006903B55b61a72D0015CDC852c8a4a"
      ); //v5 busd
    } else {
      setLoadinggrem(true);
    }

    if (from == "BNB") {
      var pid = 0;
    } else if (from == "BUSD" && old !== 6) {
      var pid = 0;
    } else if (from == "BUSD" && old == 6) {
      // TO NOTE : in v1 farm contract, pid for BUSD is 1. For v2, its 0.
      var pid = 1;
    } else if (from == "TMOON") {
      var pid = 0;
    } else if (from == "STAG") {
      var pid = 0;
    } else if (from == "EGOLD") {
      var pid = 0;
    }
    var locDeposit = 0;

    //get Farm Deposit balance
    await farmInstance.methods
      .deposited(pid, ls.get("usradd"))
      .call(function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        locDeposit = res;
      });
    await farmInstance.methods
      .withdraw(pid, locDeposit)
      .send({
        from: wallet.account,
      })
      .on("receipt", async function (hashh) {
        console.log("Farm Deposit removed");
        localStorage.setItem("flag", "true");
        await getTokbal();
        setLoadinggrem(false);
        setLoadinggrem2(false);
        setLoadinggrem3(false);
        store.addNotification({
          id: "addliq-complete",
          title: "SUCCESS",
          message: "Farm deposit removed",
          type: "success",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
      })
      .on("error", function (error, hashh) {
        console.log("error");
        setLoadinggadd(false);
        setLoadinggrem(false);
        setLoadinggrem2(false);
        setLoadinggrem3(false);
        store.addNotification({
          id: "addliq-error",
          title: "Error",
          message: error.message,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 8000,
          },
        });
      });
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      <LoginCheck />
      <div class="flxrow">
        {/* ----first column----- */}
        <div class="flxcolumn">
          <div className="app-content trcontent tfarmheight">
            <div className="tagtrade-content">
              <h3 className="heading">Tagcoin Exchange</h3>
              <h5 className="headingin">
                Tagcoin Price :{" "}
                {state.loading
                  ? "0"
                  : parseFloat(state.data[0].TAG_USD).toFixed(4) + " USD "}
                {marketstate.loading || state.loading ? (
                  "0%"
                ) : state.data[0].TAG_USD > marketstate.data[0].open_usd ? (
                  <>
                    <svg
                      height="24px"
                      viewBox="0 0 20 28"
                      width="24px"
                      fill="#30e630"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M7 14l5-5 5 5z" />
                    </svg>
                    <span className="greenlight">
                      {parseFloat(
                        ((state.data[0].TAG_USD -
                          marketstate.data[0].open_usd) /
                          marketstate.data[0].open_usd) *
                          100
                      ).toFixed(2) + "%"}
                    </span>
                  </>
                ) : (
                  <>
                    <svg
                      height="24px"
                      viewBox="0 0 20 28"
                      width="24px"
                      fill="#ff0000"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M7 10l5 5 5-5z" />
                    </svg>
                    <span className="redlight">
                      {parseFloat(
                        ((marketstate.data[0].open_usd -
                          state.data[0].TAG_USD) /
                          marketstate.data[0].open_usd) *
                          100
                      ).toFixed(2) + "%"}
                    </span>
                  </>
                )}
              </h5>
              <div>
                <center>
                  <img
                    src="https://tagprotocol.s3.us-east-1.amazonaws.com/POWERED%20BY%20PANCAKESWAP.png"
                    alt=""
                    style={{ margin: "auto" }}
                  />
                </center>
              </div>

              <div className="tagtrade"></div>
              <ul className="nav nav-tabs">
                <li className="">
                  <a
                    href=""
                    onClick={() => {
                      history.push("/swap");
                    }}
                  >
                    SWAP
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      history.push("/liquidity");
                    }}
                  >
                    LIQUIDITY
                  </a>
                </li>
                <li>
                  <a href="" className="active show">
                    LP FARM
                  </a>
                </li>
              </ul>
            </div>
            <div className="body tmoonfarmbody">
              <ul className="accinfo-list pb-2" style={{ marginBottom: "5px" }}>
                <li>
                  <label>
                    {from}/{to} LPT{" "}
                  </label>
                  <span>
                    {noround(
                      parseFloat(web3.utils.fromWei(pairbalance), "ether"),
                      4
                    )}{" "}
                    LPT
                  </span>
                </li>
                <li>
                  <label>TAG</label>
                  <span>
                    {noround(
                      parseFloat(web3.utils.fromWei(tagbalance), "ether"),
                      4
                    )}{" "}
                    TAG / $
                    {numFormatter(
                      noround(
                        parseFloat(web3.utils.fromWei(tagbalance), "ether") *
                          (state.loading
                            ? "0"
                            : parseFloat(state.data[0].TAG_USD).toFixed(4)),
                        4
                      )
                    )}
                  </span>
                </li>
              </ul>

              <div className="form-container">
                <div
                  className="field-container"
                  style={{ background: "rgb(222 240 255)", padding: "12px" }}
                >
                  <label
                    className="labl"
                    htmlFor="name"
                    style={{ padding: "5px" }}
                  >
                    Input
                  </label>
                  <label
                    className="labl"
                    htmlFor="bal"
                    style={{ padding: "5px", float: "right" }}
                  >
                    Balance :{" "}
                    {noround(
                      parseFloat(web3.utils.fromWei(pairbalance), "ether"),
                      4
                    )}
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      id="bnb"
                      className="inp"
                      maxLength="10"
                      type="text"
                      name="fromvalue"
                      style={{
                        background: "transparent",
                        border: "0px",
                        outline: "none",
                      }}
                      placeholder="0.0"
                      onChange={(e) => handlepairChange(e.target.value)}
                      value={pairval}
                    />
                    <div
                      style={{
                        display: "inline-flex",
                        position: "absolute",
                        right: "8px",
                        zIndex: "101",
                      }}
                    >
                      <button
                        className="lpfmax"
                        style={{ cursor: "pointer", outline: "0px" }}
                        onClick={(e) =>
                          setPairval(
                            noround(
                              parseFloat(
                                web3.utils.fromWei(pairbalance),
                                "ether"
                              ),
                              8
                            )
                          )
                        }
                      >
                        MAX
                      </button>
                      <Dropdownlpt />
                    </div>
                  </div>
                </div>

                <div
                  className="field-container trloadsm"
                  style={{
                    marginTop: "7px",
                    gridColumn: "1",
                    marginRight: "1%",
                    borderRadius: "5px",
                    display: from == "TMOONx" ? "none" : "inherit",
                  }}
                >
                  <button
                    className={
                      loadinggaptok === true ? "liqbutton" : "liqbutton disnone"
                    }
                    onClick={approveToken}
                    disabled={!tokenapp}
                  >
                    APPROVE LPT
                    <Loader />
                  </button>
                </div>
                <div
                  className="field-container trloadsm"
                  style={{
                    marginTop: "7px",
                    gridColumn: "2/3",
                    gridRow: "2",
                    marginLeft: "1%",
                    borderRadius: "5px",
                    display: from == "TMOONx" ? "none" : "inherit",
                  }}
                >
                  <button
                    className={
                      loadinggadd === true ? "liqbutton" : "liqbutton disnone"
                    }
                    onClick={depositFarm}
                    disabled={tokenapp}
                  >
                    DEPOSIT
                    <Loader />
                  </button>
                </div>
              </div>
              <ul
                className="accinfo-list pb-2 infodetz"
                style={{ marginTop: "2%" }}
              >
                <li className="extradets">
                  <label>YOUR DEPOSIT</label>
                  <span>
                    {noround(
                      parseFloat(
                        web3.utils.fromWei(deposit.toString()),
                        "ether"
                      ),
                      4
                    )}{" "}
                    LPT / $
                    {numFormatter(
                      from == "EGOLD"
                        ? noround(
                            parseFloat(
                              (
                                lpegoldbusdval *
                                parseFloat(
                                  web3.utils.fromWei(deposit.toString()),
                                  "ether"
                                )
                              ).toString()
                            ),
                            4
                          )
                        : from == "BNB"
                        ? noround(
                            parseFloat(
                              (
                                lpbnbval *
                                parseFloat(
                                  web3.utils.fromWei(deposit.toString()),
                                  "ether"
                                )
                              ).toString()
                            ),
                            4
                          )
                        : from == "BUSD"
                        ? noround(
                            parseFloat(
                              (
                                lpbusdval *
                                parseFloat(
                                  web3.utils.fromWei(deposit.toString()),
                                  "ether"
                                )
                              ).toString()
                            ),
                            4
                          )
                        : from == "TMOON"
                        ? noround(
                            parseFloat(
                              (
                                lptmoonval *
                                parseFloat(
                                  web3.utils.fromWei(deposit.toString()),
                                  "ether"
                                )
                              ).toString()
                            ),
                            4
                          )
                        : noround(
                            parseFloat(
                              (
                                lpstagval *
                                parseFloat(
                                  web3.utils.fromWei(deposit.toString()),
                                  "ether"
                                )
                              ).toString()
                            ),
                            4
                          )
                    )}
                  </span>
                </li>
                <li className="extradets">
                  <label>FARMING SHARE</label>
                  <span>
                    {noround(
                      (noround(
                        parseFloat(
                          web3.utils.fromWei(deposit.toString()),
                          "ether"
                        ),
                        4
                      ) /
                        noround(
                          parseFloat(
                            web3.utils.fromWei(totallp.toString()),
                            "ether"
                          ),
                          4
                        )) *
                        100,
                      4
                    )}{" "}
                    %
                  </span>
                </li>
                <li className="extradets">
                  <label>{from == "EGOLD" ? "EGOLD" : "TAG"} FARMED</label>
                  <span>
                    {noround(
                      parseFloat(
                        web3.utils.fromWei(tagearn.toString()),
                        "ether"
                      ),
                      6
                    )}{" "}
                    {from == "EGOLD" ? "EGOLD" : "TAG"} / $
                    {numFormatter(
                      noround(
                        parseFloat(
                          web3.utils.fromWei(tagearn.toString()),
                          "ether"
                        ) *
                          (state.loading
                            ? "0"
                            : parseFloat(
                                from == "EGOLD"
                                  ? egoldusd
                                  : state.data[0].TAG_USD
                              ).toFixed(4)),
                        6
                      )
                    )}
                  </span>
                </li>
                <li className="extradets" style={{ marginTop: "18px" }}>
                  <label>1 LPT</label>
                  <span>
                    $
                    {from == "BNB"
                      ? noround(parseFloat(lpbnbval.toString()), 4)
                      : from == "BUSD"
                      ? noround(parseFloat(lpbusdval.toString()), 4)
                      : from == "TMOON"
                      ? noround(parseFloat(lptmoonval.toString()), 4)
                      : from == "STAG"
                      ? noround(parseFloat(lpstagval.toString()), 4)
                      : noround(parseFloat(lpegoldbusdval.toString()), 4)}
                  </span>
                </li>
                <li className="extradets">
                  <label>YIELD PER BLOCK</label>
                  <span>
                    {noround(
                      parseFloat(
                        web3.utils.fromWei(yieldpb.toString()),
                        "ether"
                      ),
                      6
                    )}{" "}
                    {from == "EGOLD" ? "EGOLD" : "TAG"}
                  </span>
                </li>
                <li className="extradets">
                  <label>LPT STAKED</label>
                  <span>
                    {noround(
                      parseFloat(
                        web3.utils.fromWei(totallp.toString()),
                        "ether"
                      ),
                      4
                    )}{" "}
                    LPT
                  </span>
                </li>
                <li className="extradets" style={{ marginTop: "18px" }}>
                  <label>LPT DEPOSIT VALUE</label>
                  <span>
                    $
                    {parseFloat(
                      noround(
                        (from == "BNB"
                          ? noround(parseFloat(lpbnbval.toString()), 4)
                          : from == "BUSD"
                          ? noround(parseFloat(lpbusdval.toString()), 4)
                          : from == "TMOON"
                          ? noround(parseFloat(lptmoonval.toString()), 4)
                          : from == "STAG"
                          ? noround(parseFloat(lpstagval.toString()), 4)
                          : noround(parseFloat(lpegoldbusdval.toString()), 4)) *
                          noround(
                            parseFloat(
                              web3.utils.fromWei(totallp.toString()),
                              "ether"
                            ),
                            4
                          ),
                        3
                      )
                    ).toLocaleString()}
                  </span>
                </li>
                <li className="extradets">
                  <label>
                    ANNUAL {from == "EGOLD" ? "EGOLD" : "TAG"} YIELD
                  </label>
                  <span>
                    {state.loading
                      ? "..."
                      : " " +
                        noround(
                          parseFloat(
                            web3.utils.fromWei(yieldpb.toString()),
                            "ether"
                          ) *
                            20 *
                            60 *
                            24 *
                            365,
                          3
                        ) +
                        (from == "EGOLD" ? " EGOLD / " : " TAG / ") +
                        numFormatter(
                          noround(
                            parseFloat(
                              web3.utils.fromWei(yieldpb.toString()),
                              "ether"
                            ) *
                              20 *
                              60 *
                              24 *
                              365 *
                              (from == "EGOLD"
                                ? parseFloat(
                                    egtradeData.data[0].TAG_USD
                                  ).toFixed(5)
                                : parseFloat(state.data[0].TAG_USD).toFixed(5)),
                            6
                          )
                        ) +
                        " USD"}{" "}
                  </span>
                </li>
                <li className="extradets">
                  <label>APY</label>
                  <span>
                    {state.loading
                      ? "..."
                      : noround(
                          (noround(
                            parseFloat(
                              web3.utils.fromWei(yieldpb.toString()),
                              "ether"
                            ) *
                              20 *
                              60 *
                              24 *
                              365 *
                              (from == "EGOLD"
                                ? parseFloat(
                                    egtradeData.data[0].TAG_USD
                                  ).toFixed(5)
                                : parseFloat(state.data[0].TAG_USD).toFixed(5)),
                            6
                          ) /
                            noround(
                              (from == "BNB"
                                ? noround(parseFloat(lpbnbval.toString()), 4)
                                : from == "BUSD"
                                ? noround(parseFloat(lpbusdval.toString()), 4)
                                : from == "TMOON"
                                ? noround(parseFloat(lptmoonval.toString()), 4)
                                : from == "STAG"
                                ? noround(parseFloat(lpstagval.toString()), 4)
                                : noround(
                                    parseFloat(lpegoldbusdval.toString()),
                                    4
                                  )) *
                                noround(
                                  parseFloat(
                                    web3.utils.fromWei(totallp.toString()),
                                    "ether"
                                  ),
                                  4
                                ),
                              3
                            )) *
                            100,
                          3
                        )}{" "}
                    %
                  </span>
                </li>
              </ul>
              <div className="form-container">
                <div
                  className="field-container trload"
                  style={{
                    marginTop: "0%",
                    gridColumn: "1/3",
                    borderRadius: "5px",
                  }}
                >
                  <button
                    className={
                      loadinggrem === true ? "liqbutton" : "liqbutton disnone"
                    }
                    onClick={removeFarm}
                  >
                    CLAIM AND WITHDRAW
                    <Loader />
                  </button>
                </div>
                <div
                  className="field-container trloadsm"
                  style={{
                    marginTop: "3%",
                    gridArea: "2 / auto / auto / span 2",
                    borderRadius: "5px",
                    marginBottom: "0px",
                    display: from == "BNB" ? "inherit" : "none",
                  }}
                >
                  <ul
                    className="accinfo-list infodetz"
                    style={{ margin: "0px", paddingBottom: "2px" }}
                  >
                    <li
                      style={{
                        fontSize: "12px",
                        backgroundColor: "rgb(222 240 255)",
                        justifyContent: "center",
                      }}
                    >
                      <label>
                        V4 Balance :{" "}
                        {noround(
                          parseFloat(web3.utils.fromWei(bnbv2), "ether"),
                          4
                        )}{" "}
                        LPT
                      </label>
                    </li>
                  </ul>
                </div>
                <div
                  className="field-container trloadsm"
                  style={{
                    marginTop: "3%",
                    gridArea: "2 / auto / auto / span 2",
                    borderRadius: "5px",
                    marginBottom: "0px",
                    display: from == "BUSD" ? "inherit" : "none",
                  }}
                >
                  <ul
                    className="accinfo-list infodetz"
                    style={{ margin: "0px", paddingBottom: "2px" }}
                  >
                    <li
                      style={{
                        fontSize: "12px",
                        backgroundColor: "rgb(222 240 255)",
                        justifyContent: "center",
                      }}
                    >
                      <label>
                        V5 Balance :{" "}
                        {noround(
                          parseFloat(web3.utils.fromWei(busdv1), "ether"),
                          4
                        )}{" "}
                        LPT
                      </label>
                    </li>
                  </ul>
                </div>

                <div
                  className="field-container trloadsm"
                  style={{
                    marginTop: "1%",
                    gridColumn: "1/3",
                    marginLeft: "1%",
                    marginBottom: "8px",
                    borderRadius: "5px",
                    display: from == "BNB" ? "inherit" : "none",
                  }}
                >
                  <button
                    className={
                      loadinggrem3 === true ? "liqbutton" : "liqbutton disnone"
                    }
                    onClick={() => {
                      removeFarm(11);
                    }}
                  >
                    WITHDRAW V4 LPT
                    <Loader />
                  </button>
                </div>
                <div
                  className="field-container trloadsm"
                  style={{
                    marginTop: "1%",
                    gridColumn: "1/3",
                    marginLeft: "1%",
                    marginBottom: "8px",
                    borderRadius: "5px",
                    display: from == "BUSD" ? "inherit" : "none",
                  }}
                >
                  <button
                    className={
                      loadinggrem3 === true ? "liqbutton" : "liqbutton disnone"
                    }
                    onClick={() => {
                      removeFarm(12);
                    }}
                  >
                    WITHDRAW V5 LPT
                    <Loader />
                  </button>
                </div>

                <div
                  className="field-container trloadsm"
                  style={{
                    marginTop: "3%",
                    gridArea: "2 / auto / auto / span 2",
                    borderRadius: "5px",
                    marginBottom: "0px",
                    display: from == "TMOON" ? "inherit" : "none",
                  }}
                >
                  <ul
                    className="accinfo-list infodetz"
                    style={{ margin: "0px", paddingBottom: "2px" }}
                  >
                    <li
                      style={{
                        fontSize: "12px",
                        backgroundColor: "rgb(222 240 255)",
                      }}
                    >
                      <label>
                        V4 Balance :{" "}
                        {noround(
                          parseFloat(web3.utils.fromWei(tmoonv4), "ether"),
                          4
                        )}{" "}
                        LPT
                      </label>
                      <label>
                        V5 Balance :{" "}
                        {noround(
                          parseFloat(web3.utils.fromWei(tmoonv5), "ether"),
                          4
                        )}{" "}
                        LPT
                      </label>
                    </li>
                  </ul>
                </div>
                <div
                  className="field-container trloadsm"
                  style={{
                    marginTop: "1%",
                    gridArea: "3/1",
                    borderRadius: "5px",
                    marginBottom: "8px",
                    display: from == "TMOON" ? "inherit" : "none",
                  }}
                >
                  <button
                    className={
                      loadinggrem2 === true ? "liqbutton" : "liqbutton disnone"
                    }
                    onClick={() => {
                      removeFarm(1);
                    }}
                  >
                    WITHDRAW V4 LPT
                    <Loader />
                  </button>
                </div>
                <div
                  className="field-container trloadsm"
                  style={{
                    marginTop: "1%",
                    gridArea: "3/2",
                    marginLeft: "1%",
                    marginBottom: "8px",
                    borderRadius: "5px",
                    display: from == "TMOON" ? "inherit" : "none",
                  }}
                >
                  <button
                    className={
                      loadinggrem3 === true ? "liqbutton" : "liqbutton disnone"
                    }
                    onClick={() => {
                      removeFarm(5);
                    }}
                  >
                    WITHDRAW V5 LPT
                    <Loader />
                  </button>
                </div>

                <div
                  className="field-container trloadsm"
                  style={{
                    marginTop: "3%",
                    gridArea: "2 / auto / auto / span 2",
                    borderRadius: "5px",
                    marginBottom: "0px",
                    display: from == "STAG" ? "inherit" : "none",
                  }}
                >
                  <ul
                    className="accinfo-list infodetz"
                    style={{ margin: "0px", paddingBottom: "2px" }}
                  >
                    <li
                      style={{
                        fontSize: "13px",
                        backgroundColor: "rgb(222 240 255)",
                      }}
                    >
                      <label>V1 BALANCE </label>
                      {noround(
                        parseFloat(web3.utils.fromWei(stagv1), "ether"),
                        4
                      )}{" "}
                      LPT<label>V2 BALANCE </label>
                      {noround(
                        parseFloat(web3.utils.fromWei(stagv2), "ether"),
                        4
                      )}{" "}
                      LPT
                    </li>
                  </ul>
                </div>

                <div
                  className="field-container trloadsm"
                  style={{
                    marginTop: "1%",
                    gridArea: "3/1",
                    borderRadius: "5px",
                    marginBottom: "8px",
                    display: from == "STAG" ? "inherit" : "none",
                  }}
                >
                  <button
                    className={
                      loadinggrem2 === true ? "liqbutton" : "liqbutton disnone"
                    }
                    onClick={() => {
                      removeFarm(3);
                    }}
                  >
                    WITHDRAW V1 LPT
                    <Loader />
                  </button>
                </div>
                <div
                  className="field-container trloadsm"
                  style={{
                    marginTop: "1%",
                    gridArea: "3/2",
                    marginLeft: "1%",
                    marginBottom: "8px",
                    borderRadius: "5px",
                    display: from == "STAG" ? "inherit" : "none",
                  }}
                >
                  <button
                    className={
                      loadinggrem3 === true ? "liqbutton" : "liqbutton disnone"
                    }
                    onClick={() => {
                      removeFarm(4);
                    }}
                  >
                    WITHDRAW V2 LPT
                    <Loader />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----second column----  */}
        <div className="flxcolumn">
          <div className="app-content trcontent tfarmheight">
            <FarmData />
          </div>
        </div>
      </div>

      <Datarow />
      <Burnrow />
      <StatRow />

      <br />

      <Footer />
    </>
  );
}
export default LPFarm;

//EOF
