import React, { useState, useEffect } from "react";
import "./app/css/bootstrap.min.css";
import "./app/css/style.css";
import "./app/css/affStyle.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header";
import TradePan from "./components/TradePancake";
import LiquidityPan from "./components/LiquidityPancake";
import LPFarm from "./components/LPFarm";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Maintenance from "./components/Maintenance";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import CommonDataContextProvider from "./components/CommonDataContext";

function App() {
  const [isSiteDown, setIsSiteDown] = useState(false);
  return (
    <>
      {isSiteDown ? (
        <Maintenance />
      ) : (
        <CommonDataContextProvider>
          <div className="App">
            <ReactNotification />
            <Router>
              <Header />
              <Route path="/" exact component={TradePan} />
              <Route path="/swap" exact component={TradePan} />
              <Route path="/liquidity" exact component={LiquidityPan} />
              <Route path="/lpfarm" exact component={LPFarm} />
              <Route path="/privacypolicy" exact component={PrivacyPolicy} />
            </Router>
          </div>
        </CommonDataContextProvider>
      )}
    </>
  );
}

export default App;
