import React, { useContext } from "react";

export class TradeChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
  }
  componentDidMount() {
    const script1 = document.createElement("script");
    script1.src = "https://s3.tradingview.com/tv.js";
    this._ref.current.appendChild(script1);
    const script2 = document.createElement("script");
    script2.innerHTML =
      "new TradingView.widget(" +
      JSON.stringify({
        autosize: true,
        symbol: "PANCAKESWAP:TAGUSD_E9AC7B",
        interval: "60",
        timezone: "Etc/UTC",
        theme: "light",
        style: "1",
        locale: "en",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        withdateranges: true,
        hide_side_toolbar: true,
        show_popup_button: true,
        popup_width: "1000",
        popup_height: "650",
        container_id: "tradingview_428b8",
      }) +
      ");";
    this._ref.current.appendChild(script2);
  }
  render() {
    return (
      <div class="tradingview-widget-container" ref={this._ref}>
        <div id="tradingview_428b8" style={{ height: "320px" }}></div>
      </div>
    );
  }
}
