import React, { useState, createContext, useEffect, useCallback } from "react";

export const CommonDataContext = createContext();

const CommonDataContextProvider = (props) => {
  const [runCommand, setrunCommand] = useState("0");
  const [from, setFrom] = useState("BUSD");
  const [to, setTo] = useState("TAG");
  const [state, setState] = useState({
    data: null,
    error: false,
    loading: true,
  });
  const [latstate, setLatstate] = useState({
    data: null,
    error: false,
    loading: true,
  }); //to get last trade value
  const [liqstate, setLiqstate] = useState({
    data: null,
    error: false,
    loading: true,
  });
  const [farmstate, setFarmstate] = useState({
    data: null,
    error: false,
    loading: true,
  });
  const [marketstate, setMarketstate] = useState({
    data: null,
    error: false,
    loading: true,
  });
  const [marketval, setMarketval] = useState({
    supply: 0,
    totaltag: 0,
    totalusd: 0,
  });
  const [burnstate, setBurnstate] = useState({
    main: null,
    data: null,
    error: false,
    loading: true,
  });
  const [tagholders, setTagholders] = useState(0);
  const [htagreg, setHtagreg] = useState(0);
  const [htaghold, setHtaghold] = useState(0);
  const [stakecount, setStakecount] = useState(0);
  const [tradestate, setTradestate] = useState(false); //for trade data usd - bnb
  const [marketdatastate, setMarketdatastate] = useState(false); //for market data chart usd - bnb
  const [lpbnbval, setLpbnbval] = useState(0);
  const [lpbusdval, setLpbusdval] = useState(0);
  const [lptmoonval, setLptmoonval] = useState(0);
  const [lpstagval, setLpstagval] = useState(0);
  const [tagusd, setTagusd] = useState(0);
  const [bnblpt, setBNBlpt] = useState(0);
  const [busdtpl, setBUSDlpt] = useState(0);
  const [tmoonlpt, setTMOONlpt] = useState(0);
  const [staglpt, setSTAGlpt] = useState(0);
  const [unmintedtoken, setUnmintedtoken] = useState(0);

  let value = {
    runCommand,
    setrunCommand,
    state,
    setState,
    latstate,
    setLatstate,
    liqstate,
    setLiqstate,
    farmstate,
    setFarmstate,
    marketstate,
    setMarketstate,
    marketval,
    setMarketval,
    tagholders,
    setTagholders,
    htagreg,
    setHtagreg,
    htaghold,
    setHtaghold,
    stakecount,
    setStakecount,
    tradestate,
    setTradestate,
    from,
    setFrom,
    to,
    setTo,
    marketdatastate,
    setMarketdatastate,
    lpbnbval,
    setLpbnbval,
    lpbusdval,
    setLpbusdval,
    lptmoonval,
    setLptmoonval,
    lpstagval,
    setLpstagval,
    unmintedtoken,
    setUnmintedtoken,
    tagusd,
    setTagusd,
    bnblpt,
    setBNBlpt,
    busdtpl,
    setBUSDlpt,
    tmoonlpt,
    setTMOONlpt,
    staglpt,
    setSTAGlpt,
    burnstate,
    setBurnstate,
  };

  return (
    <CommonDataContext.Provider value={{ ...value }}>
      {props.children}
    </CommonDataContext.Provider>
  );
};
export default CommonDataContextProvider;
