import React, { useState, useEffect, useContext } from "react";
import {
  ConnectionRejectedError,
  useWallet,
  UseWalletProvider,
} from "use-wallet";
import { CommonDataContext } from "./CommonDataContext";
import { useHistory } from "react-router-dom";
import metamax from "../images/metamaxicon.png";
import trustwall from "../images/trustwallet.png";
import bcwall from "../images/binanceicon.png";
import spwall from "../images/safewallet.png";
import wcwall from "../images/walletconnecticon.png";
import tpwall from "../images/tokenpocketicon.png";
import otwall from "../images/webwalleticon.png";

const Web3 = require("web3");
const web3 = new Web3("https://bsc-dataseed.binance.org/");

function Header() {
  const valContext = useContext(CommonDataContext);
  const { runCommand, setrunCommand } = valContext;
  const wallet = useWallet();
  let history = useHistory();

  const [usacc, setUsacc] = useState();
  const [usbal, setUsbal] = useState("0");
  const [usstat, setUsstat] = useState("disconnected");

  useEffect(() => {
    initVals();
  }, []);

  useEffect(async () => {
    initValsasync();
  });

  const initVals = async () => {
    if (localStorage.getItem("wallstat") == "connected") {
      setrunCommand(1);
      setUsacc(localStorage.getItem("usradd"));
      setUsbal(localStorage.getItem("usrbal"));
      setUsstat("connected");
      await wallet
        .connect(localStorage.getItem("usrconnt"))
        .then(function (e) {
          console.log("connected refresh");
        })
        .catch(function (e) {
          console.log(e);
        });
    }
  };

  const initValsasync = async () => {
    console.log(wallet.status);
    if (wallet.status === "connected") {
      setrunCommand(1);
      console.log("walletadd -----", wallet.account);
      localStorage.setItem("wallstat", "connected");
      localStorage.setItem("usradd", wallet.account);
      localStorage.setItem("usrconnt", wallet.connector);
      localStorage.setItem("usrbal", wallet.balance);
      setUsacc(localStorage.getItem("usradd"));
      setUsbal(localStorage.getItem("usrbal"));
      setUsstat("connected");
    }
  };

  function disconnectall() {
    console.log("disconneeeect");
    wallet.reset();
    setrunCommand(0);
    console.log("Resettings happening");
    localStorage.setItem("usradd", "");
    localStorage.setItem("usrbal", "");
    localStorage.setItem("wallstat", "disconnected");
    localStorage.removeItem("walletconnect");
    setUsacc(localStorage.getItem("usradd"));
    setUsbal(localStorage.getItem("usrbal"));
    setUsstat("disconnected");
  }

  function addBscChain() {
    if (window.ethereum) {
      handleEthereum();
    } else {
      window.addEventListener("ethereum#initialized", handleEthereum, {
        once: true,
      });
      setTimeout(handleEthereum, 3000);
    }

    function handleEthereum() {
      const { ethereum } = window;
      if (ethereum) {
        console.log("Ethereum successfully detected!");
      } else {
        console.error("Ethereum not detected.");
      }
    }
    const BSC_MAINNET_PARAMS = {
      chainId: "0x38",
      chainName: "Binance Smart Chain",
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://bsc-dataseed.binance.org/"],
      blockExplorerUrls: ["https://bscscan.io/"],
    };
    if (window.ethereum) {
      window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [BSC_MAINNET_PARAMS],
        })
        .then(() => {
          console.log("BSC Added");
          wallet.connect();
        })
        .catch((error) => console.log(error));
    }
  }
  const blockNumber = wallet.getBlockNumber();

  function noround(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  return (
    <>
      <nav className="navbar-custom navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <a
            className="navbar-brand logo-image"
            href=""
            style={{ display: "inline-flex", cursor: "default" }}
          >
            <div id="logoo"></div>{" "}
            <h5
              className="hidem"
              style={{
                fontSize: "11px",
                margin: "9px 0px 0px 20px",
                color: "#ededed",
              }}
            >
              NATIVE TOKEN OF TAGPROTOCOL
            </h5>
          </a>

          <div className="" id="navbarsExampleDefault">
            <span className="nav-item">
              {usstat === "connected" ? (
                <div className="usrdet">
                  <div className="usracc hidem">
                    Wallet :{" "}
                    {usacc.substring(0, 4) +
                      "..." +
                      usacc.substring(usacc.length - 4)}{" "}
                  </div>

                  <button
                    className="btn-outline-sm discc"
                    style={{
                      margin: "0px",
                      borderRadius: "0px",
                      border: "1px solid #fff",
                    }}
                    onClick={() => {
                      disconnectall();
                    }}
                  >
                    disconnect
                  </button>
                </div>
              ) : (
                <a
                  className="btn-outline-sm"
                  href="#open-modal"
                  style={{
                    margin: "0px",
                    borderRadius: "0px",
                    border: "1px solid #fff",
                  }}
                >
                  Connect Wallet
                </a>
              )}
            </span>
          </div>
        </div>
      </nav>
      <div id="open-modal" className="modal-window">
        <div>
          <a href="#" title="Close" className="modal-close">
            Close
          </a>
          {usstat === "connected" ? (
            <div style={{ textAlign: "center" }}>
              <div className="usraccmod" style={{ color: "black" }}>
                Wallet :{" "}
                {usacc.substring(0, 4) +
                  "..." +
                  usacc.substring(usacc.length - 4)}
              </div>
              <button
                className="btn-outline-sm moddisc"
                onClick={() => {
                  disconnectall();
                }}
              >
                disconnect
              </button>
            </div>
          ) : (
            <div>
              <h1 style={{ marginTop: "10px" }}>Connect using : </h1>

              <ul className="wallet-lists">
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      addBscChain();
                      wallet.connect();
                    }}
                  >
                    Metamask <img src={metamax} />
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => wallet.connect("walletconnect")}>
                    WalletConnect <img src={wcwall} />
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => wallet.connect("injected")}>
                    TrustWallet <img src={trustwall} />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      window.ethereum = window.BinanceChain;
                      wallet.connect();
                    }}
                  >
                    Binance chain Wallet <img src={bcwall} />
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => wallet.connect("injected")}>
                    SafePal Wallet <img src={spwall} />
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => wallet.connect("injected")}>
                    TokenPocket <img src={tpwall} />
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => wallet.connect("injected")}>
                    Other Web3 Wallets <img src={otwall} />
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default Header;
